/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZXComponent } from 'src/app/models/component';
import { Preferences } from 'src/app/models/preferences.model';
import { SolutionSpace } from 'src/app/models/solution';
import { zxProjectModel } from 'src/app/models/zxProject.model';
import { BackendCreateProject, BackendUpdateProject, ProjectsService } from 'src/app/services/api/projects.service';
import { Group, UserService } from 'src/app/services/api/user.service';
import { PreferencesComponent } from 'src/app/shared/preferences/preferences.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ArchiveDialogComponent } from './archive-dialog/archive-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { ResponseObj } from 'src/app/models/errorHandling';
import { UserQuery } from 'src/app/services/api/state/user.query';
import { TranslatePipe } from '@ngx-translate/core';

class ProjectForm {
  public solution: string | undefined;
  initialSolution: string;
  setInitialSolution(solution: string): void {
    this.initialSolution = solution;
    this.solution = solution;
  }
  public solutionChange = new EventEmitter<string>();
  updateSolution(solutionControl: UntypedFormControl): void {
    if (typeof solutionControl.value == 'string') {
      this.solution = solutionControl.value;
      this.solutionChange.emit(this.solution);
    } else {
      this.solution = undefined;
    }
  }
  get solutionValue(): SolutionSpace {
    return this.solution === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;
  }

  public name: string;
  initialName: string;
  setInitialName(name: string): void {
    this.initialName = name;
    this.name = name;
  }
  updateName(newName: string): void {
    this.name = newName;
  }
  nameValidated(): boolean {
    return !!(this.name && this.name.length > 0);
  }

  public description: string;
  initialDescription: string;
  updateDescription(newDescription: string): void {
    this.description = newDescription;
  }
  setInitialDescription(description: string): void {
    this.initialDescription = description;
    this.description = description;
  }
  public group: string | undefined;
  initialGroup: string;
  setInitialGroup(group: string): void {
    this.initialGroup = group;
    this.group = group;
  }
  updateGroup(groupControl: UntypedFormControl): void {
    this.group = typeof groupControl.value == 'string' ? groupControl.value : undefined;
  }
  groupValidated(): boolean {
    return !!this.group;
  }

  components: {
    id: string;
    name: string;
    type: string;
    loading?: boolean;
  }[] = [];

  valid(): boolean {
    return !!(this.solution && this.name && this.name.length > 0 && this.group);
  }

  pristine(): boolean {
    return this.initialName === this.name && this.initialDescription === this.description;
  }

  asBackendCreate(): BackendCreateProject {
    return {
      solution: this.solution,
      groupId: this.group,
      name: this.name,
      description: this.description ? this.description : undefined,
    };
  }

  asBackendUpdate(): BackendUpdateProject {
    return {
      name: this.name,
      description: this.description,
    };
  }
}

@Component({
  selector: 'app-zx-project-form',
  templateUrl: './zx-project-form.component.html',
  styleUrls: ['./zx-project-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TranslatePipe],
})
export class ZxProjectFormComponent implements OnInit, OnDestroy {
  @Input() project: zxProjectModel | undefined;
  @ViewChild(PreferencesComponent, { static: true })
  preferencesComponent: PreferencesComponent;
  @ViewChild('projectStepper', { static: true }) projectStepper: MatStepper;
  skipNextControl: UntypedFormControl = new UntypedFormControl();
  form: ProjectForm = new ProjectForm();
  groups: Group[];
  solution: SolutionSpace;

  origin: string;
  doubleSolutionAccess = false;
  preferencesGroup: UntypedFormGroup;
  preferencesData: Preferences;
  preferencesUpdating = false;
  loading = false;
  hasProject: boolean;
  private paramSubscription: Subscription;
  createProjectSubscription: Subscription;
  editProjectSubscription: Subscription;

  iconPath: string;
  navText: string;
  groupValues: { value: string; label: string }[];
  perferenceSolution: SolutionSpace;

  private orgSubscription: Subscription;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private userService: UserService,
    private userQuery: UserQuery,
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private responseHandler: ResponseHandlerService,
    private translate: TranslatePipe
  ) {
    this.matIconRegistry.addSvgIcon(
      `poly-chevron-right`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-24-chevron-in.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `poly-chevron-left`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-24-chevron-collapse.svg')
    );
  }

  ngOnInit(): void {
    this.hasProject = this.project !== undefined;

    if (this.projectStepper.selectedIndex === 0 && !this.hasProject) {
      this.iconPath = '/assets/images/icon-40-close.svg';
      this.navText = 'SHARED.CANCEL';
    } else {
      this.iconPath = '/assets/images/icon-40-back.svg';
      this.navText = 'project.FORM.BACK_TO_PROJECTS';
    }

    this.preferencesGroup = this.preferencesComponent.createGroup();

    this.orgSubscription = this.userQuery.organisation$.subscribe((organisation) => {
      const canAccess: string[] = organisation?.canAccess || [];

      if (canAccess?.includes('utilization') && canAccess?.includes('humidity')) {
        this.doubleSolutionAccess = true;
      }
      this.perferenceSolution = this.doubleSolutionAccess ? (this.form.solution as SolutionSpace) : this.solution;
    });

    this.paramSubscription = this.route.queryParams.subscribe((el) => {
      this.origin = typeof el.origin == 'string' ? el.origin : '';

      this.solution = el.solution === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;
      this.form.setInitialSolution(this.solution);

      if (this.project) {
        this.form.setInitialName(this.project.name);
        this.form.setInitialGroup(this.project.groupId);
        if (this.project.description) this.form.setInitialDescription(this.project.description);
      }

      this.fetchGroups();
    });
  }

  ngOnDestroy(): void {
    this.orgSubscription && this.orgSubscription.unsubscribe();
    this.paramSubscription && this.paramSubscription.unsubscribe();
    this.createProjectSubscription && this.createProjectSubscription.unsubscribe();
    this.editProjectSubscription && this.editProjectSubscription.unsubscribe();
  }

  get stepHeader(): string {
    if (this.projectStepper) {
      let headerText;
      switch (this.projectStepper.selectedIndex) {
        case 0:
          headerText = this.project ? 'project.FORM.EDIT_PROJECT_HEADER' : 'project.FORM.CREATE_PROJECT_HEADER';
          break;
        default:
          headerText = 'project.FORM.PROJECT_SETTING_HEADER';
      }
      return headerText;
    }
    return '';
  }

  clickNav(): void {
    void this.router.navigate(['/projects']);
  }

  transformComponents(components: ZXComponent[]): { id: string; name: string; type: string; loading: boolean }[] {
    return components.map((c) => {
      return {
        name: c.name,
        id: c.id,
        type: typeof c.type == 'string' ? c.type : c.type.name,
        loading: false,
      };
    });
  }

  onSubmit(): void {
    this.loading = true;
    const solution = this.doubleSolutionAccess ? (this.form.solution as SolutionSpace) || this.solution : this.solution;

    if (!this.project) {
      this.createProjectSubscription = this.projectsService
        .createProject(this.form.asBackendCreate(), solution)
        .subscribe((res) => {
          if (this.skipNextControl.value === true) {
            void this.router.navigate([this.origin]);
          } else {
            this.projectsService.getProject(this.solution, res).subscribe(
              (project) => {
                this.project = project;
                if (this.projectStepper?.selected?.completed !== undefined) {
                  this.projectStepper.selected.completed = true;
                }
                const obj: ResponseObj = {
                  title: this.translate.transform('project.FORM.SUBMIT_TITLE_CREATE'),
                  message: this.translate.transform('project.FORM.SUBMIT_MESSAGE_CREATE', {
                    projectName: this.form.name,
                  }),
                };
                this.snackbarService.openSnackBar(obj, 'CREATE');
                this.projectStepper.next(); //
                this.loading = false;
              },
              (error: HttpErrorResponse) => this.responseHandler.error(error)
            );
          }
        });
    } else {
      const projectName = this.project.name;
      this.loading = true;
      this.editProjectSubscription = this.projectsService
        .editProject(this.form.asBackendUpdate(), this.project.id)
        .subscribe({
          next: () => {
            this.loading = false;
            if (this.projectStepper?.selected?.completed !== undefined) {
              this.projectStepper.selected.completed = true;
            }
            const obj: ResponseObj = {
              title: this.translate.transform('project.FORM.SUBMIT_TITLE_UPDATE'),
              message: this.translate.transform('project.FORM.SUBMIT_MESSAGE_UPDATE', {
                projectName: projectName,
              }),
            };
            this.snackbarService.openSnackBar(obj, 'UPDATE');
            window.location.reload();
          },
          error: (error: HttpErrorResponse) => {
            this.responseHandler.error(error);
          },
        });
    }
  }

  openArchiveDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '18vh';
    dialogConfig.minWidth = '18vw';

    if (!this.project) return;

    const projectId = this.project.id;
    const projectName = this.project.name;
    const dialogRef = this.dialog.open(ArchiveDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.projectsService.archiveProject(projectId).subscribe({
          next: () => {
            this.dialog.closeAll();
            const obj: ResponseObj = {
              title: this.translate.transform('project.FORM.SUBMIT_TITLE_ARCHIVED'),
              message: this.translate.transform('project.FORM.SUBMIT_MESSAGE_ARCHIVED', {
                projectName: projectName,
              }),
            };
            this.snackbarService.openSnackBar(obj, 'ARCHIVE');
            window.location.reload();
          },
          error: (error: HttpErrorResponse) => {
            this.responseHandler.error(error);
          },
        });
      }
    });
  }

  updatePrefs(): void {
    if (!this.project) return;

    const preferencesGroup = this.preferencesComponent.formGroup;
    const prefs: Preferences = {
      shockMeasurementActive: preferencesGroup.value['shockMeasurementActive'],
      shockMeasurementValue: +preferencesGroup.value['shockMeasurementValue'],
      humidityWarning: preferencesGroup.value['humidityWarning'],
      humidityWarningValue:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        preferencesGroup.value['humidityWarningValue'] &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        parseInt(preferencesGroup.value['humidityWarningValue'], 10),
      shockWarning: preferencesGroup.value['shockWarning'],
      shockWarningValue: +preferencesGroup.value['shockWarningValue'],
    };

    this.preferencesUpdating = true;
    const projectName = this.project.name;
    this.projectsService.updateProjectPreferences(this.project.id, prefs).subscribe(() => {
      this.projectStepper.next();
      this.preferencesUpdating = false;
      this.preferencesData = prefs;
      this.snackbarService.openSnackBar(
        {
          title: this.translate.transform('project.FORM.SUBMIT_TITLE_UPDATE'),
          message: this.translate.transform('project.FORM.SUBMIT_MESSAGE_PREFERENCES', {
            projectName: projectName,
          }),
        },
        'UPDATE'
      );
    });
  }

  onSolutionControlChange(): void {
    // when user changes solution get the correct groups to polulate dropdown
    this.fetchGroups();
  }

  putComponent(id: string): void {
    if (this.project) {
      this.projectsService.addComponent(id, this.project.id);
    }
  }

  fetchGroups(): void {
    this.userService.getGroupsForProjectCreation().subscribe((groups) => {
      this.groups = groups;
      this.groupValues = this.groups ? this.groups.map((g) => ({ value: g.id, label: g.name })) : [];

      if (this.groups.length === 1) {
        this.form.setInitialGroup(this.groups[0].id);
      }
    });
  }

  onCancel(): void {
    void this.router.navigate([this.origin]);
  }
}
