<a *ngIf="!isMobile()" class="nav-cancel" (click)="clickNav()" id="btn-close-component-window">
  <span>
    <svg-icon src="{{ getNavIcon() }}"></svg-icon>
    {{ getNavText() }}
  </span>
</a>

<ng-template #stepOne>
  <form #componentForm="ngForm">
    <a (click)="clickNav()">
      <h2 [fxShow]="this.responsiveService.isMobile()">
        <svg-icon src="/assets/images/icon-40-back.svg"></svg-icon>

        <ng-container *ngIf="component === undefined"> Create component </ng-container>

        <ng-container *ngIf="component !== undefined"> Edit component </ng-container>
      </h2>
    </a>

    <div class="form-field-container" *ngIf="doubleSolutionAccess">
      <h4 class="add-device-header">Logistics Hub</h4>

      <p class="form-field-header">Logisitics Hub type</p>

      <app-select-field
        id="select-logistics-type"
        class="select-field inverted"
        (selectionChange)="form.updateSolution($event)"
        [optionArray]="[
          { value: 'utilization', label: 'Utilization' },
          { value: 'humidity', label: 'Humidity' }
        ]"
        [defaultChoice]="form.solution"
      ></app-select-field>
    </div>

    <div *ngIf="!isMobile() || !!!component" class="add-device-wrapper">
      <div class="form-field-container">
        <p class="form-field-header">
          Find logistics hub
          <!--
          <span *ngIf="form.devices.length < 1" class="required">*</span>
          -->
        </p>

        <app-search-field
          id="input-logistics-id-search"
          class="search-field inverted"
          (keyUp)="updateDeviceSearch($event)"
          (focused)="updateDeviceSearch()"
          [placeHolder]="'Enter ID found on logistics hub label'"
          #searchcontrol
        ></app-search-field>
      </div>

      <div *ngIf="devices.length > 0" style="width: 100%">
        <h3 style="font-weight: 500; color: #aaaaaa">Search results</h3>

        <table style="width: 100%">
          <thead>
            <tr>
              <th>Device</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let device of devices">
              <tr>
                <td>{{ mapBackendDevice(device.name) }}</td>

                <td *ngIf="form.deviceAdded(device)" class="addedcell">
                  <button
                    [attr.data-id]= "device.dbId"
                    [id]="'btn-added-' + device.name"
                    mat-flat-button color="info" ngProjectAs="buttons">
                    ✓ Added
                  </button>
                </td>

                <td *ngIf="!form.deviceAdded(device)" class="addcell">
                  <button
                    [disabled]="form.devices.length > 0"
                    (click)="addDevice($event)"
                    [attr.data-id]= "device.dbId"
                    [id]="'btn-add-' + device.name"
                    mat-flat-button
                    color="primary"
                    ngProjectAs="buttons"
                  >
                    + Add
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf="form.devices.length > 0" style="width: 100%">
        <hr style="width: 50px; float: left; margin: 20px 0px" />

        <h4 style="font-size: 20px; font-weight: 700; margin-bottom: 30px; clear: both">Added device</h4>

        <table style="width: 100%">
          <thead>
            <tr>
              <th>Device</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let device of addedDevices()">
              <tr>
                <td>{{ mapBackendDevice(device.name) }}</td>

                <td class="addedcell">
                  <button
                    (click)="removeDevice($event)"
                    [attr.data-id]= "device.dbId"
                    [id]="'btn-remove-' + device.name"
                    mat-flat-button
                    color="info"
                    ngProjectAs="buttons"
                  >
                    X Remove
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <!--  <mat-checkbox color="primary"> Skip next steps </mat-checkbox> -->
    </div>

    <div class="form-field-container">
      <hr *ngIf="!isMobile() || !!!component" class="add-device-wrapper" />

      <h4 class="add-device-header">Components</h4>

      <p class="form-field-header">
        Component ID
        <span *ngIf="!form.name || form.name.length < 1" class="required">*</span>
      </p>

      <app-input-field
        id="input-component-id"
        class="input-field inverted"
        (valueChanged)="form.updateName($event)"
        [defaultValue]="form.name"
      ></app-input-field>
    </div>

    <div class="form-field-container">
      <p class="form-field-header">
        Component Type
        <span *ngIf="typePristine()" class="required">*</span>
      </p>

      <app-type-field
        id="select-component-type"
        class="select-field inverted"
        (selectionChange)="form.updateType($event)"
        (createNew)="createComponentType()"
        (edit)="editComponentType($event)"
        [solution]="solutionValue"
        [optionArray]="types"
        [defaultChoice]="initialType"
      ></app-type-field>
    </div>

    <div class="form-field-container">
      <p class="form-field-header">
        Group
        <span *ngIf="groupPristine()" class="required">*</span>
      </p>

      <app-select-field
        id="select-group"
        class="select-field inverted"
        (selectionChange)="form.updateGroup($event)"
        [disabled]="!(groups && groups.length > 1)"
        [optionArray]="groupValues"
        [defaultChoice]="defaultGroup"
      ></app-select-field>
    </div>
  </form>

  <div class="archive-update-container">
    <hr *ngIf="isMobile()" />

    <div class="archive-container">
      <button
        class="btn-archive btn-red"
        mat-flat-button
        id="btn-archive"
        color="warning"
        (click)="openArchiveDialog()"
        *ngIf="component !== undefined && component.state !== ComponentStateEnum.Archived"
      >
        Archive component
      </button>

      <button
        mat-flat-button
        id="btn-update"
        *ngIf="component !== undefined && isMobile()"
        (click)="onUpdate()"
        color="primary"
        [ngClass]="'btn-update'"
        [disabled]="saveButtonDisabled()"
        [class.spinner]="creatingComponent"
      >
        Update
      </button>
    </div>
  </div>

  <hr *ngIf="!isMobile()" />

  <!-- end add device -->

  <div *ngIf="!isMobile() || !!!component" class="button-wrapper">
    <div style="float: left">
      <button (click)="onCancel()" id="btn-cancel" [ngClass]="'btn-grey'" mat-flat-button>Cancel</button>
    </div>

    <div style="float: right">
      <button
        mat-flat-button
        *ngIf="component === undefined"
        (click)="onSubmit()"
        color="primary"
        id="btn-create-component"
        [ngClass]="'btn-submit'"
        [disabled]="!formValid()"
        [class.spinner]="creatingComponent"
      >
        Create component
      </button>

      <button
        mat-flat-button
        *ngIf="component !== undefined"
        (click)="onUpdate()"
        color="primary"
        id="btn-update-editComponent"
        [ngClass]="'btn-submit'"
        [disabled]="saveButtonDisabled()"
        [class.spinner]="updatingComponent"
      >
        Update
      </button>

      <button
        mat-flat-button
        [ngClass]="'btn-grey'"
        *ngIf="!!component"
        id="btn-preferences"
        style="margin-left: 8px"
        (click)="onClickGoPreference()"
      >
        Preferences
        <mat-icon svgIcon="poly-chevron-right"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<!-- hidden for the project
-->

<div [hidden]="isMobile()" style="margin: 0 auto; width: 700px" class="component-form">
  <header>
    <h2 class="step-header">{{ stepHeader }}</h2>
  </header>

  <mat-horizontal-stepper class="stepper" [linear]="component === undefined" #componentStepper>
    <mat-step [completed]="component !== undefined" label="{{ !!component ? 'Edit component' : 'Create component' }}">
      <div class="mat-mt-l"></div>

      <ng-container *ngTemplateOutlet="stepOne"></ng-container>
    </mat-step>

    <mat-step label="Preferences">
      <h2 class="mat-mt-l">Use project preferences</h2>

      <mat-slide-toggle
        [disabled]="initialProject === '00000000-0000-0000-0000-000000000000'"
        [formControl]="inheritFromProjectControl"
        id="toggle-use-project-preference"
        ngDefaultControl
        style="font-weight: 600"
      >
        {{ "component.DETAILS.PREFERENCES.PROJECT-PREFERENCES.SLIDER-TEXT" | translate }}
      </mat-slide-toggle>

      <div style="font-weight: 500">
        {{ "component.DETAILS.PREFERENCES.PROJECT-PREFERENCES.SUB-HEADER" | translate }}
      </div>

      <div class="separator"></div>

      <app-preferences
        [enabled]="inheritFromProjectControl.value ? false : true"
        [solution]="solution"
        [currentPreferences]="getCurrentPreferences()"
      ></app-preferences>

      <div class="button-wrapper">
        <div style="float: left">
          <button id="btn-edit-component" mat-flat-button [ngClass]="'btn-grey'" matStepperPrevious>
            <mat-icon svgIcon="poly-chevron-left"></mat-icon>
            Edit component
          </button>
        </div>

        <div style="float: right">
          <button
            id="btn-update-editPreferences"
            mat-flat-button
            (click)="onUpdatePreferences()"
            color="primary"
            [disabled]="preferencesSubmitDisabled()"
            [class.spinner]="preferencesUpdating"
            style="margin-right: 10px"
          >
            Update
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<div [hidden]="!isMobile() || component !== undefined" class="mobile">
  <ng-container *ngTemplateOutlet="mobileStepper"></ng-container>
</div>

<div *ngIf="isMobile() && component !== undefined" class="mobile">
  <ng-container *ngTemplateOutlet="stepOne"></ng-container>
</div>

<!-- Mobile stepper -->

<ng-template #mobileStepper>
  <a (click)="navigate()" style="padding-left: 8px; padding-right: 8px">
    <h2 class="pl-xxs" style="margin-bottom: 0px">
      <svg-icon *ngIf="stepIndex < 2" src="/assets/images/icon-40-back.svg"></svg-icon>

      <svg-icon width="24px" height="24px" *ngIf="stepIndex === 2" src="/assets/icons/cancel.svg"></svg-icon>

      <span class="ml-xxs">Create component</span>
    </h2>
  </a>

  <mat-horizontal-stepper class="mobile-stepper" [linear]="true" #stepper [selectedIndex]="stepIndex">
    <form #componentForm="ngForm" class="component-form">
      <mat-step label="Device details" [editable]="true">
        <ng-template matStepLabel></ng-template>

        <div class="form-field-container">
          <h4 class="add-device-header-mobile">Logistics Hub</h4>
        </div>

        <ng-container *ngIf="doubleSolutionAccess">
          <p class="form-field-header">Logisitics Hub type</p>

          <span *ngIf="!this.solution" class="required">*</span>
          <app-select-field
            id="select-logistics-type"
            class="select-field inverted"
            (selectionChange)="form.updateSolution($event)"
            [optionArray]="[
              { value: 'utilization', label: 'Utilization' },
              { value: 'humidity', label: 'Humidity' }
            ]"
            [defaultChoice]="form.solution"
          ></app-select-field>
        </ng-container>

        <div *ngIf="!!!component" class="add-device-wrapper">
          <div class="pr-xxs pb-m subtitle">Enter the 12 digit code found on the label on the Logistics Hub.</div>

          <div class="form-field-container">
            <p class="form-field-header">
              Logistics Hub ID
              <span *ngIf="form.devices.length < 1" class="required">*</span>
            </p>

            <app-search-field
              id="input-logistics-id-search"
              class="search-field inverted mt-l mb-l"
              (keyUp)="updateDeviceSearch($event)"
              (focused)="updateDeviceSearch()"
              [placeHolder]="searchPlaceHolder"
              #searchcontrol
            ></app-search-field>
          </div>

          <div *ngIf="devices.length > 0" style="width: 100%">
            <h3 *ngIf="!isMobile()" style="font-weight: 500; color: #aaaaaa">Search results</h3>

            <table style="width: 100%">
              <thead>
                <tr>
                  <th class="align-left">Device</th>
                </tr>
              </thead>

              <tbody>
                <ng-container *ngFor="let device of devices">
                  <tr>
                    <td>{{ mapBackendDevice(device.name) }}</td>

                    <td *ngIf="form.deviceAdded(device)" class="addedcell">
                      <button
                        [attr.data-id]="device.dbId"
                        [id]="'btn-mobile-added-' + device.name"
                        mat-flat-button
                        [disabled]="true"
                        color="info"
                        ngProjectAs="buttons"
                      >
                        ✓ Added
                      </button>
                    </td>

                    <td *ngIf="!form.deviceAdded(device)" class="addcell">
                      <button
                        [disabled]="form.devices.length > 0"
                        (click)="addDevice($event)"
                        [attr.data-id]="device.dbId"
                        [id]="'btn-mobile-add-' + device.name"
                        mat-flat-button
                        color="primary"
                        ngProjectAs="buttons"
                      >
                        + Add
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <hr class="mt-l mb-l" />

        <div class="flex-col">
          <button
            mat-flat-button
            color="primary"
            id="btn-next"
            matStepperNext
            (click)="stepIndex = 1"
            [disabled]="form.devices.length === 0"
            class="btn-submit button-mobile"
          >
            Next
          </button>

          <button mat-flat-button class="button-mobile" id="btn-cancel" (click)="onCancel()">Cancel</button>
        </div>
      </mat-step>

      <mat-step label="component-details" [editable]="true">
        <ng-template matStepLabel></ng-template>

        <h4 class="add-device-header-mobile">Component details</h4>

        <div class="mb-m pr-xxs subtitle">Input the component ID and select the Component type.</div>

        <div class="form-field-container">
          <p class="form-field-header">
            Component ID
            <span *ngIf="!form.name || form.name.length < 1" class="required">*</span>
          </p>

          <app-input-field
            id="input-component-id"
            class="input-field inverted"
            (valueChanged)="form.updateName($event)"
            [defaultValue]="form.name"
          ></app-input-field>
        </div>

        <div class="form-field-container">
          <p class="form-field-header">
            Component Type
            <span *ngIf="typePristine()" class="required">*</span>
          </p>

          <app-type-field
            id="select-component-type"
            class="select-field inverted"
            (selectionChange)="form.updateType($event)"
            [enableCreation]="false"
            [isEditable]="false"
            [solution]="solutionValue"
            [optionArray]="types"
            [defaultChoice]="initialType"
          ></app-type-field>

          <ng-container *ngIf="groups && groups.length > 1">
            <p class="form-field-header">
              Group
              <span *ngIf="typePristine()" class="required">*</span>
            </p>

            <app-select-field
              id="select-component-group"
              class="select-field inverted"
              (selectionChange)="form.updateGroup($event)"
              [disabled]="!(groups && groups.length > 1)"
              [optionArray]="groupValues"
              [defaultChoice]="defaultGroup"
            ></app-select-field>
          </ng-container>
        </div>

        <hr class="mt-l mb-l" />

        <div class="flex-col">
          <button
            mat-flat-button
            id="btn-create-component"
            color="primary"
            class="btn-submit button-mobile"
            [disabled]="!formValid()"
            [class.spinner]="creatingComponent"
            [disabled]="!creatingComponent"
            (click)="saveComponent($event)"
          >
            Create Component
          </button>

          <button mat-flat-button id="btn-back" (click)="stepIndex = 0" matStepperPrevious>Back</button>
        </div>
      </mat-step>

      <mat-step label="summary">
        <div class="title mt-m">Component created</div>

        <div class="subtitle mb-m">
          The component has been created.
          <br />
          Please verify the details below.
        </div>

        <div class="label">Logistics Hub ID:</div>

        <div class="title mb-xxs mr-m">{{ form.devices[0]?.name?.toUpperCase() }}</div>

        <div class="label">Component ID:</div>

        <div class="title mb-xxs mr-m">{{ form.name }}</div>

        <div class="label">Component type:</div>

        <div class="title mb-xxs mr-m">{{ form.type?.name }}</div>

        <hr class="mt-l mb-l" />

        <div class="flex-col">
          <button mat-flat-button color="primary" class="btn-submit button-mobile mb-xxs" (click)="clickNav()">
            Done
          </button>

          <button mat-flat-button class="button-mobile btn-grey" (click)="resetCreationPath()">Create another</button>
        </div>
      </mat-step>
    </form>
  </mat-horizontal-stepper>
</ng-template>

<!-- Mobile section-->
