import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserManagement } from 'src/app/models/user-management-list';
import { AdminService } from 'src/app/services/api/admin.service';
import { SortDirection } from 'src/app/services/api/components.service';
import { TableColumn, TableRow } from 'src/app/shared/table/table.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  displayedColumns: TableColumn[] = [];
  users: UserManagement[];
  dataSource: TableRow[];
  groups: { value: string; label: string }[] = [];

  searchControl = new UntypedFormControl();
  statusControl = new UntypedFormControl('_all_');
  groupControl = new UntypedFormControl('_all_');
  sortControl = new UntypedFormControl();

  status: string | undefined = '_all_';
  group: string | undefined = '_all_';
  sortBy: string | undefined = 'user';
  sortDirection: string | undefined = '0';

  loaded = false;

  constructor(private adminService: AdminService) {
    this.displayedColumns = [
      { name: 'Name', key: 'name' },
      { name: 'Email', key: 'email' },
      { name: 'Groups', key: 'groups' },
      { name: 'Status', key: 'status' },
    ];
  }

  ngOnInit(): void {
    this.searchControl.setValue('');

    this.adminService.getUsersUsermanagement().subscribe((userManagementList: UserManagement[]) => {
      this.users = [];
      userManagementList.forEach((userManagement) => {
        this.users.push(userManagement);
        this.updateData();
        this.updateGroups();
      });
    });
  }

  setStatusControl(statusControl: UntypedFormControl): void {
    this.statusControl = statusControl;
    this.status = this.statusControl.value !== '_all_' ? (this.statusControl.value as string) : undefined;
    this.getData().subscribe((users) => {
      this.users = users;
      this.updateGroups();
      this.updateData();
      this.loaded = true;
    });
    this.updateData();
  }

  setGroupControl(groupControl: UntypedFormControl): void {
    this.groupControl = groupControl;
    this.group = this.groupControl.value !== '_all_' ? (this.groupControl.value as string) : undefined;
    this.getData().subscribe((users) => {
      this.users = users;
      this.updateGroups();
      this.updateData();
      this.loaded = true;
    });
    this.updateData();
  }

  setSortControl(sortControl: UntypedFormControl): void {
    this.sortBy = typeof sortControl.value == 'string' ? sortControl.value.toLocaleLowerCase() : undefined;
    this.getData().subscribe((users) => {
      this.users = users;
      this.updateGroups();
      this.updateData();
      this.loaded = true;
    });
    this.updateData();
  }

  updateData(search?: string): void {
    const status: string | undefined =
      this.statusControl.value !== '_all_' ? (this.statusControl.value as string) : undefined;

    const sortDirection: SortDirection = this.sortDirection == '0' ? SortDirection.ASC : SortDirection.DESC;

    const group: string | undefined =
      this.groupControl.value !== '_all_' ? (this.groupControl.value as string) : undefined;

    let filteredUsers = this.users.filter((user) => {
      if (search) {
        if (user.name.toLocaleLowerCase().indexOf(search) < 0 && user.email.toLocaleLowerCase().indexOf(search) < 0) {
          return false;
        }
      }

      if (status) {
        if (user.status.toLocaleLowerCase().indexOf(status) < 0) return false;
      }

      if (group) {
        if (user.groups.indexOf(group) < 0) return false;
      }

      return true;
    });

    if (this.sortBy) {
      filteredUsers = filteredUsers.sort((a, b) => {
        if (this.sortBy === 'name') {
          if (sortDirection == SortDirection.ASC)
            return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1;
          else return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? -1 : 1;
        }
        if (this.sortBy === 'status') {
          if (sortDirection == SortDirection.ASC)
            return a.status.toLocaleLowerCase() > b.status.toLocaleLowerCase() ? 1 : -1;
          else return a.status.toLocaleLowerCase() > b.status.toLocaleLowerCase() ? -1 : 1;
        }
        if (this.sortBy === 'group') {
          if (sortDirection == SortDirection.ASC)
            return a.groups[0].toLocaleLowerCase() > b.groups[0].toLocaleLowerCase() ? 1 : -1;
          else return a.groups[0].toLocaleLowerCase() > b.groups[0].toLocaleLowerCase() ? -1 : 1;
        }

        return 0;
      });
    }

    this.setTableRowDataSource(filteredUsers);
  }

  updateGroups(): void {
    const uniqueGroups = [
      ...new Set(
        this.users
          .filter((user) => user.groups)
          .map((user) => user.groups[0])
          .filter((group) => group.length > 0)
      ),
    ];

    this.groups = uniqueGroups.map((p) => ({ value: p, label: p }));
    this.groups.unshift({ value: '_all_', label: 'All' });
  }

  getData(): Observable<UserManagement[]> {
    return this.adminService.getUsersUsermanagement();
  }

  getOrigin(): string {
    return document.location.pathname;
  }

  private setTableRowDataSource(users: UserManagement[]) {
    this.dataSource = users.map((user) => ({
      name: {
        value: user.name,
      },
      email: {
        value: user.email,
      },
      // TODO - LIS-126: https://polytechas.atlassian.net/browse/LIS-126
      //        Handle the mapping of groups.
      // groups: {
      //   value: user.groups,
      // },
      status: {
        value: user.status,
      },
    }));
  }
}
