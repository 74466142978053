import { positionType } from '../services/api/components.service';
import { ComponentState } from './component';

export class Datalog {
  device: string;
  project: string;
  temperature: number | null;
  humidity: number;
  humidityWarningValue: number;
  incidentId: string;
  radarMeasurementInMillimeter: number | string;
  availabilityRangeHighInMillimeter: number;
  availabilityRangeLowInMillimeter: number;
  shock: number;
  shockThreshold: number;
  accelerationX: number;
  accelerationY: number;
  accelerationZ: number;
  position: {
    lat: number;
    lng: number;
    type: positionType;
    accuracy: number;
  };
  countryId: string;
  countryName: string;
  regionId: string;
  regionName: string;
  placeId: string;
  placeName: string;
  postcodeId: string;
  postcodeName: string;
  voltage: number;
  time: Date;
  state: ComponentState;
}
