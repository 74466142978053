import { Transform, Type } from 'class-transformer';
import { ActivityType } from '../shared/recent-history/recent-history.component';
import { ZXComponent } from './component';
import { Preferences } from './preferences.model';

class ProjectActivityDetails {
  @Transform(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (act) => ActivityType[act.value.toString() as keyof typeof ActivityType]
  )
  activity: ActivityType;
  text: string;
  @Type(() => Date)
  timestamp: Date;
}

export class zxProjectModel {
  id: string;
  name: string;
  description?: string;
  @Type(() => Date)
  created: Date;
  archived: boolean;
  componentCount: number;
  availableCount: number;
  inUseCount: number;
  inReturnCount: number;
  inServiceCount: number;
  activeHumidityIncidentCount: number;
  owner: string;
  groupName: string;
  groupId: string;
  @Type(() => ProjectActivityDetails)
  projectActivity: ProjectActivityDetails[];
  components: ZXComponent[];
  preferences: Preferences;

  public constructor(init?: Partial<zxProjectModel>) {
    Object.assign(this, init);
  }
}
