import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectService implements CanActivate {
  constructor() {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const id = route.url[route.url.length - 1].toString();
    const mac: string[] = [];
    let idx = 0;
    while (idx < id.length) {
      const sub = id.substring(idx, idx + 2);
      mac.push(sub);
      idx += 2;
    }

    let params = new HttpParams();
    params = params.set('id', mac.join(':'));
    window.location.href = `/components/create?${params.toString()}`;
    return false;
  }
}
