import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  currentlyInProjectIcon,
  componentLink,
  getPositionType,
  getStateCycles,
  parseSolutionSpace,
  projectLink,
  projectComponentLink,
} from '../../models/transforms';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable, of, range, Subject, Subscription } from 'rxjs';
import * as XLSX from 'xlsx';

import { BackendComponent, BackendComponentList, ComponentsService } from 'src/app/services/api/components.service';
import { NumericUtilOrHumid, SolutionSpace } from '../../models/solution';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExportDialogComponent, FilterQueryParams } from 'src/app/shared/export-dialog/export-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
import { ArchiveComponentsComponent } from './archive-components/archive-components.component';
import { SetProjectDialogComponent } from './set-project-dialog/set-project-dialog.component';
import { mergeMap, take } from 'rxjs/operators';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProjectsService } from 'src/app/services/api/projects.service';
import { ComponentState, stateToUtilizationIconMap, stateToUtilizationTextMap } from 'src/app/models/component';
import { Title } from '@angular/platform-browser';
import { ComponentsMenuComponent, ExportType } from 'src/app/shared/components-menu/components-menu.component';
import { FilterTypes } from 'src/app/models/filter';
import { TableColumn, TableRow } from 'src/app/shared/table/table.component';
import { incidentValueFromHeathComponent, statusIconFromComponentHealth } from 'src/app/models/component-health';
import { formatDate, isArrayWithElements, isDefined } from 'src/app/shared/utils';
import { Sort } from '@angular/material/sort';
import { Area } from 'src/app/models/map/map.model';
import { FilterOption, FilterType } from 'src/app/shared/components-menu/filter/filter.component';
import { UserQuery } from 'src/app/services/api/state/user.query';
import { ProjectList } from 'src/app/models/project-list';
import { SortDirection, ComponentsSortOrder } from 'src/app/models/page-request';

export type FilterUpdate = {
  search: string;
  archived: boolean;
  types: string[] | undefined;
  projects: string[] | undefined;
  availables: {
    value: FilterTypes | string;
    label: string;
    isSelected?: boolean;
    currentCycleGTE?: number | undefined;
    currentCycleLTE?: number | undefined;
  }[];
  filteredCountries: string[];
  filteredRegions: string[];
  filteredPlaces: string[];
  filteredPostcodes: string[];
  lastCommunicationTimeFrom: Date | undefined;
  lastCommunicationTimeTo: Date | undefined;
  measurement: string | undefined;
  humidityValues: { LTE: number | undefined; GTE: number | undefined };
  temperatureValues: { LTE: number | undefined; GTE: number | undefined };
  area: Area | undefined;
};

type CurrentCycleFilter = {
  currentCycleAvailableDaysGTE: number | undefined;
  currentCycleAvailableDaysLTE: number | undefined;
  currentCycleInReturnDaysGTE: number | undefined;
  currentCycleInReturnDaysLTE: number | undefined;
  currentCycleInServiceDaysGTE: number | undefined;
  currentCycleInServiceDaysLTE: number | undefined;
  currentCycleInUseDaysGTE: number | undefined;
  currentCycleInUseDaysLTE: number | undefined;
};

@Component({
  selector: 'app-zx-components',
  templateUrl: './zx-components.component.html',
  styleUrls: ['./zx-components.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TranslatePipe],
})
export class ZxComponentsComponent implements OnInit, OnDestroy {
  @ViewChild(ComponentsMenuComponent) componentsMenuComponent: ComponentsMenuComponent;

  isProjectComponentlist = false;

  components: BackendComponent[];
  dataSource: TableRow[] = [];
  displayedColumns: TableColumn[] = [];
  resultCount = 0;
  selectedIds: { name: string; id: string }[] = [];
  ENUM_UtilOrHumid: typeof SolutionSpace = SolutionSpace;
  types: { value: string; label: string }[] = [];
  projects: { value: string; label: string }[] = [];
  loaded = false;

  componentFilter: Partial<FilterUpdate> = {
    availables: [],
    temperatureValues: { LTE: undefined, GTE: undefined },
    humidityValues: { LTE: undefined, GTE: undefined },
    archived: false,
  };
  forcedProject: string; // only to be used when isProjectComponentlist=true

  pageSizeOptions = [500, 450, 400, 350, 300, 250, 200, 150, 100, 50];
  links = [
    { id: 'utilization', label: 'Utilization', path: '../utilization' },
    { id: 'humidity', label: 'Humidity', path: '../humidity' },
  ];
  activeLink = 'utilization';
  private paramSubscription: Subscription;

  solutionSpace: SolutionSpace;
  canAccess: string[] = [];

  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  utilizationFiltersOption: Array<FilterOption> = [
    {
      filterType: FilterType.TYPES,
      label: 'Component Types',
      icon: '/assets/icons/componentTypes.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.DAYSINSTATE,
      label: 'Days In State',
      icon: '/assets/icons/daysInState.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.LASTCOMMUNICATED,
      label: 'Last communicated',
      icon: '/assets/icons/calendar.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.LOCATION,
      label: 'Location',
      icon: '/assets/icons/icon-16-tag.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.UTILIZATION,
      label: 'Utilization',
      icon: '/assets/icons/utilization-device.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.ARCHIVED,
      label: 'Archived',
      icon: '/assets/icons/icon-16-archive.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.AREA,
      label: 'Area',
      icon: '/assets/icons/icon-16-archive.svg',
      shouldShowInList: false,
    },
  ];
  humFiltersOption: Array<FilterOption> = [
    {
      filterType: FilterType.TYPES,
      label: 'Component Types',
      icon: '/assets/icons/componentTypes.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.HUMIDITY,
      label: 'Humidity',
      icon: '/assets/icons/icon-16-humidity.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.LASTCOMMUNICATED,
      label: 'Last communicated',
      icon: '/assets/icons/calendar.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.LOCATION,
      label: 'Location',
      icon: '/assets/icons/icon-16-tag.svg',
      shouldShowInList: true,
    },

    {
      filterType: FilterType.TEMPERATURE,
      label: 'Temperature',
      icon: '/assets/icons/temperature.svg',
      shouldShowInList: true,
    },

    {
      filterType: FilterType.ARCHIVED,
      label: 'Archived',
      icon: '/assets/icons/icon-16-archive.svg',
      shouldShowInList: true,
    },
    {
      filterType: FilterType.AREA,
      label: 'Area',
      icon: '/assets/icons/icon-16-archive.svg',
      shouldShowInList: false,
    },
  ];

  sortBy: ComponentsSortOrder = ComponentsSortOrder.COMPONENT_NAME;
  defaultSortBy = 'component';
  sortDirection: SortDirection = SortDirection.ASC;
  pageNumber = 1;
  pageSize = 100;
  projectPageNumber = 1;
  projectPageSize = 100;
  totalItemCount = 0;
  clearSelection = false;

  loadingMapData = false;
  mapData: BehaviorSubject<BackendComponent[]> = new BehaviorSubject<BackendComponent[]>([]);
  loadingProgressPercentage = 0;
  showMap = false;

  private userQueryOrgSub: Subscription;

  constructor(
    private componentsService: ComponentsService,
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public responsiveService: ResponsiveService,
    private responseHandler: ResponseHandlerService,
    private snackbarService: SnackbarService,
    private titleService: Title,
    private cdr: ChangeDetectorRef,
    private userQuery: UserQuery
  ) {}

  ngOnInit(): void {
    this.userQueryOrgSub = this.userQuery.organisation$.subscribe((organisation) => {
      this.canAccess = organisation?.canAccess || [];
    });

    this.paramSubscription = this.route.params.subscribe((el) => {
      // check if this component is called from the project section or component list
      if (el.id) {
        // is called from the project compoennt list
        this.isProjectComponentlist = true;
        this.forcedProject = el.id as string;
      } else {
        this.titleService.setTitle(`Components`);
        // add project filterings for component List if it doesnt already exist
        const UtilizationProjectFilterIndex = this.utilizationFiltersOption.findIndex(
          (filter) => filter.filterType === FilterType.PROJECTS
        );
        if (UtilizationProjectFilterIndex === -1) {
          // project should be in the 6 position based on the alphabetical order of the filter menu
          this.utilizationFiltersOption.splice(5, 0, {
            filterType: FilterType.PROJECTS,
            label: 'Project',
            icon: '/assets/icons/icon-16-set-project.svg',
            shouldShowInList: true,
          });
        }
        const humidityProjectFilterIndex = this.humFiltersOption.findIndex(
          (filter) => filter.filterType === FilterType.PROJECTS
        );
        if (humidityProjectFilterIndex === -1) {
          this.humFiltersOption.splice(4, 0, {
            filterType: FilterType.PROJECTS,
            label: 'Project',
            icon: '/assets/icons/icon-16-set-project.svg',
            shouldShowInList: true,
          });
        }
      }

      this.activeLink = el.utilorhumid as string;
      this.solutionSpace = parseSolutionSpace(el.utilorhumid as string);
      this.displayedColumns = this.computeColumnsToDisplay();

      this.loadTypes();
      if (!this.isProjectComponentlist) this.loadProjects();
      void this.loadData().then(() => {
        this.updateTable();
      });
    });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
    if (this.userQueryOrgSub) {
      this.userQueryOrgSub.unsubscribe();
    }
  }

  prepareExportComponentData(datum: TableRow[]): Array<any> {
    const exportedKeys = this.displayedColumns.map(({ key }) => key);
    const generatedData: Record<string, string>[] = datum.map((data) =>
      Object.fromEntries(
        Object.entries(data)
          .filter(([key, _]) => key !== 'componentIconHealth' && key !== 'positionType' && exportedKeys.includes(key))
          .map(([key, tableCell]) => [
            key,
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            tableCell.value2 ? `${tableCell.value}${tableCell.value2}` : `${tableCell.value}`,
          ])
      )
    );

    return generatedData;
  }

  exportFromFE(content: TableRow[]) {
    const exportedData = this.prepareExportComponentData(content);
    const workSheet = XLSX.utils.json_to_sheet(exportedData);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Components');
    XLSX.writeFile(workBook, 'componentsList.xlsx');
  }

  loadTypes(): void {
    this.componentsService.getTypes(this.solutionSpace, { pageSize: 10000 }).subscribe((types) => {
      this.types = [
        ...types.items.map((t) => ({
          value: t.id,
          label: t.description ? `${t.name}-${t.description}` : t.name,
        })),
      ];
    });
  }

  loadProjects(): void {
    this.projectsService
      .getProjects({
        solutionSpace: this.solutionSpace,
        pageRequest: { pageNumber: this.projectPageNumber, pageSize: this.projectPageSize },
        isPositioned: false,
      })
      .subscribe((projects) => {
        this.projects = projects.items.map((project) => ({
          value: project.id,
          label: project.name,
        }));
      });
  }
  loadData(): Promise<void> {
    this.displayedColumns = this.computeColumnsToDisplay();
    return new Promise((resolve, reject) => {
      this.loaded = false;

      this.getData(this.pageSize, this.pageNumber).subscribe({
        next: (response) => {
          this.totalItemCount = response.totalItemCount;
          this.resultCount = response.totalItemCount;
          this.components = response.items;
          this.selectedIds = [];
          this.loaded = true;
          if (this.showMap) {
            this.loadMapData();
          }
          resolve();
        },
        error: (error: HttpErrorResponse) => {
          this.responseHandler.error(error);
          reject();
        },
      });
    });
  }

  loadMapData() {
    this.mapData.next([]);
    const batchSize = 300;
    const maxConcurrentCalls = 5;

    const fetchData = (batchSize: number, pageNumber: number) => {
      return this.getData(batchSize, pageNumber).pipe(take(1));
    };

    this.loadingMapData = true;
    this.loadingProgressPercentage = 0;

    fetchData(batchSize, 1).subscribe({
      next: (response) => {
        const filteredData = response.items.filter((data) => data.position);
        const totalCalls = response.pageCount - 1;
        let batchFetched = 1;
        this.mapData.next(filteredData);
        this.loadingProgressPercentage = (batchFetched / response.pageCount) * 100;

        range(2, totalCalls)
          .pipe(
            take(totalCalls),
            mergeMap((callNumber) => {
              return fetchData(batchSize, callNumber);
            }, maxConcurrentCalls)
          )
          .subscribe({
            next: (data) => {
              const filteredData = data.items.filter((data) => data.position);

              batchFetched++;
              this.loadingProgressPercentage = (batchFetched / data.pageCount) * 100;
              this.mapData.next([...this.mapData.getValue(), ...filteredData]);
            },
            complete: () => {
              this.loadingMapData = false;
            },
          });
      },
      error: (error: HttpErrorResponse) => {
        this.responseHandler.error(error);
      },
    });
  }

  updateFilter(event: FilterUpdate) {
    this.componentFilter = event;
    void this.loadData().then(() => this.updateTable());
  }

  updateTable(): void {
    this.setTableDataSource();
    this.cdr.detectChanges();
  }

  getData(pageSize: number, pageNumber: number): Observable<BackendComponentList> {
    const filterParams = this.mapFilterToParams();

    const paginationParams = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortDirection: this.sortDirection,
      sortOrder: this.sortBy,
    };

    if (this.isProjectComponentlist) {
      return this.projectsService.getComponents(this.solutionSpace, this.forcedProject, filterParams, paginationParams);
    }

    return this.componentsService.getComponents(this.solutionSpace, { pageRequest: paginationParams, ...filterParams });
  }

  private mapFilterToParams(): Record<string, FilterQueryParams | undefined> {
    let states: ComponentState[] | undefined = [];
    let currentCycleFilter: CurrentCycleFilter;

    if (isArrayWithElements(this.componentFilter.availables)) {
      states = this.componentFilter.availables
        .map((status) => this.mapFilterTypeToComponentStatus(status.value))
        .filter(isDefined) as ComponentState[];
      const lastAvailable = this.componentFilter.availables[this.componentFilter.availables.length - 1];
      currentCycleFilter = this.mapStatusToCurrentCycleFilter(lastAvailable);
    } else {
      states = [ComponentState.Available, ComponentState.InReturn, ComponentState.InService, ComponentState.InUse];
      currentCycleFilter = this.mapStatusToCurrentCycleFilter(undefined);
    }

    if (this.componentFilter.archived) {
      states = [ComponentState.Archived];
    }

    const projectIds = this.isProjectComponentlist
      ? [this.forcedProject]
      : isArrayWithElements(this.componentFilter.projects)
      ? this.componentFilter.projects
      : undefined;

    return {
      states: isArrayWithElements(states) ? states : undefined,
      likeSearch: this.componentFilter.search,
      projectIds,
      componentTypeIds: isArrayWithElements(this.componentFilter.types) ? this.componentFilter.types : undefined,
      currentCycleAvailableDaysGTE: currentCycleFilter.currentCycleAvailableDaysGTE,
      currentCycleAvailableDaysLTE: currentCycleFilter.currentCycleAvailableDaysLTE,
      currentCycleInUseDaysGTE: currentCycleFilter.currentCycleInUseDaysGTE,
      currentCycleInUseDaysLTE: currentCycleFilter.currentCycleInUseDaysLTE,
      currentCycleInReturnDaysGTE: currentCycleFilter.currentCycleInReturnDaysGTE,
      currentCycleInReturnDaysLTE: currentCycleFilter.currentCycleInReturnDaysLTE,
      currentCycleInServiceDaysGTE: currentCycleFilter.currentCycleInServiceDaysGTE,
      currentCycleInServiceDaysLTE: currentCycleFilter.currentCycleInServiceDaysLTE,
      countryIds: isArrayWithElements(this.componentFilter.filteredCountries)
        ? this.componentFilter.filteredCountries
        : undefined,
      regionIds: isArrayWithElements(this.componentFilter.filteredRegions)
        ? this.componentFilter.filteredRegions
        : undefined,
      placeIds: isArrayWithElements(this.componentFilter.filteredPlaces)
        ? this.componentFilter.filteredPlaces
        : undefined,
      postcodeIds: isArrayWithElements(this.componentFilter.filteredPostcodes)
        ? this.componentFilter.filteredPostcodes
        : undefined,
      lastCommunicationTimeFrom: this.componentFilter.lastCommunicationTimeFrom?.toISOString(),
      lastCommunicationTimeTo: this.componentFilter.lastCommunicationTimeTo?.toISOString(),
      latestExternalHumidityMeasurementValueGTE: this.componentFilter.humidityValues?.GTE,
      latestExternalHumidityMeasurementValueLTE: this.componentFilter.humidityValues?.LTE,
      latestExternalTemperatureMeasurementValueGTE: this.componentFilter.temperatureValues?.GTE,
      latestExternalTemperatureMeasurementValueLTE: this.componentFilter.temperatureValues?.LTE,
      minLat: this.componentFilter.area?.minLat,
      maxLat: this.componentFilter.area?.maxLat,
      minLon: this.componentFilter.area?.minLon,
      maxLon: this.componentFilter.area?.maxLon,
    };
  }

  private mapFilterTypeToComponentStatus(type: string | undefined): ComponentState | undefined {
    switch (type) {
      case FilterTypes.AVAILABLE:
        return ComponentState.Available;
      case FilterTypes.INUSE:
        return ComponentState.InUse;
      case FilterTypes.IN_RETURN:
        return ComponentState.InReturn;
      case FilterTypes.IN_SERVICE:
        return ComponentState.InService;
      default:
        return undefined;
    }
  }

  mapStatusToCurrentCycleFilter(
    status:
      | {
          value: FilterTypes | string;
          label: string;
          isSelected?: boolean;
          currentCycleGTE?: number | undefined;
          currentCycleLTE?: number | undefined;
        }
      | undefined
  ): CurrentCycleFilter {
    const result: CurrentCycleFilter = {
      currentCycleAvailableDaysGTE: undefined,
      currentCycleAvailableDaysLTE: undefined,
      currentCycleInReturnDaysGTE: undefined,
      currentCycleInReturnDaysLTE: undefined,
      currentCycleInServiceDaysGTE: undefined,
      currentCycleInServiceDaysLTE: undefined,
      currentCycleInUseDaysGTE: undefined,
      currentCycleInUseDaysLTE: undefined,
    };
    const componentState = this.mapFilterTypeToComponentStatus(status?.value);
    switch (componentState) {
      case ComponentState.Available:
        {
          result.currentCycleAvailableDaysGTE = status?.currentCycleGTE;
          result.currentCycleAvailableDaysLTE = status?.currentCycleLTE;
        }
        break;

      case ComponentState.InUse:
        {
          result.currentCycleInUseDaysGTE = status?.currentCycleGTE;
          result.currentCycleInUseDaysLTE = status?.currentCycleLTE;
        }
        break;

      case ComponentState.InReturn:
        {
          result.currentCycleInReturnDaysGTE = status?.currentCycleGTE;
          result.currentCycleInReturnDaysLTE = status?.currentCycleLTE;
        }
        break;

      case ComponentState.InService:
        {
          result.currentCycleInServiceDaysGTE = status?.currentCycleGTE;
          result.currentCycleInServiceDaysLTE = status?.currentCycleLTE;
        }
        break;
    }
    return result;
  }

  onSortingChanged(event: Sort): void {
    this.sortDirection = event.direction === 'asc' ? SortDirection.ASC : SortDirection.DESC;
    this.sortBy =
      event.active === 'componentId'
        ? ComponentsSortOrder.COMPONENT_NAME
        : event.active === 'lastCommunication'
        ? ComponentsSortOrder.LAST_COMMUNICATED_TIME
        : event.active === 'utilization'
        ? ComponentsSortOrder.STATE
        : event.active === 'humidity'
        ? ComponentsSortOrder.HUMIDITY
        : ComponentsSortOrder.TEMPERATURE;

    void this.loadData().then(() => this.updateTable());
  }

  onPaginationChanged(event: PageEvent): void {
    const { pageIndex, pageSize } = event;
    if (this.pageNumber === pageIndex && this.pageSize === pageSize) return;

    if (this.pageNumber !== pageIndex) {
      this.pageNumber = pageIndex;
    }
    if (this.pageSize !== pageSize) {
      this.pageSize = pageSize;
    }
    void this.loadData().then(() => this.updateTable());
  }

  onSelectionChanged(selected: TableRow[]): void {
    this.selectedIds = selected.map((row) => {
      return {
        id: row.id.value as string,
        name: row.componentId.value as string,
      };
    });
  }

  getOrigin(): string {
    return document.location.pathname;
  }

  getExportBulkOptions(ids: string[]): {
    ids: string[];
    timeStart: Date | undefined;
    timeEnd: Date | undefined;
  } {
    return {
      ids,
      timeStart: undefined,
      timeEnd: undefined,
    };
  }

  onClearSelectionTrigger() {
    void this.loadData().then(() => this.updateTable());
  }

  exportComponentsDialog(idNames: { name: string; id: string }[]): void {
    const ids = idNames.map((com) => com.id);

    this.dialog.open(ExportDialogComponent, {
      autoFocus: false,
      data: {
        solutionSpace:
          this.solutionSpace === SolutionSpace.Utilization
            ? NumericUtilOrHumid.Utilization
            : NumericUtilOrHumid.Humidity,
        componentsInputs: ids.length > 0 ? this.getExportBulkOptions(ids) : undefined,
        filterQueryParams: ids.length === 0 ? this.mapFilterToParams() : undefined,
      },
    });
  }

  openExportDialog(event: ExportType): void {
    if (event === ExportType.BE_DATA) {
      this.exportComponentsDialog(this.selectedIds);
    } else {
      if (this.selectedIds.length === 0) {
        const dataToExport = this.showMap
          ? this.mapData.getValue().map((components) => this.mapDataToTableRow(components))
          : this.dataSource;
        this.exportFromFE(dataToExport);
      } else {
        const ids = this.selectedIds.map((idsObj) => {
          return idsObj.id;
        });
        const prepareFilteredComponents = this.dataSource.filter((data) => {
          return ids.includes(data.id.value as string);
        });
        if (prepareFilteredComponents.length > 0) {
          this.exportFromFE(prepareFilteredComponents);
        }
      }
    }
  }

  archiveComponentsDialog(idNames: { name: string; id: string }[]): void {
    const ids = idNames.map((com) => com.id);
    for (const id of ids) {
      const c = this.components.find((cmp) => cmp.id === id);
      if (c?.archived) {
        // this.snackbarService.openSnackBar('One or more of the components are already archived', 'ERROR');
        return;
      }
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '400px';
    dialogConfig.maxWidth = '216px';
    const archiveSubject: Subject<string> = new Subject();
    dialogConfig.data = {
      numComponents: ids.length,
      archiveSubject,
    };
    archiveSubject.subscribe(() => {
      this.componentsService.archiveComponents(ids).subscribe(
        () => {
          this.snackbarService.openSnackBar(
            { title: 'Components archived', message: `${ids.length} components successfully archived` },
            'ARCHIVE'
          );
          this.dialog.closeAll();
          void this.loadData().then(() => this.updateTable());
        },
        (error: HttpErrorResponse) => {
          this.responseHandler.error(error);
          this.dialog.closeAll();
        }
      );
      archiveSubject.unsubscribe();
    });
    this.dialog.open(ArchiveComponentsComponent, dialogConfig);
  }

  openArchiveDialog(): void {
    this.archiveComponentsDialog(this.selectedIds);
  }

  addToProjectDialog(idNames: { name: string; id: string }[]): void {
    const ids = idNames.map((comp) => comp.id);
    const groupIds = this.components.filter((c) => ids.includes(c.id)).map((c) => c.groupId);
    const uniqueGroupIds = [...new Set(groupIds)];

    for (const id of ids) {
      const c = this.components.find((cmp) => cmp.id === id);
      if (c?.archived) {
        this.snackbarService.openSnackBar(
          { title: 'Cannot add to project', message: 'One or more of the components are archived' },
          'ERROR'
        );
        return;
      }
    }

    const projectList$ =
      uniqueGroupIds.length !== 1
        ? of<ProjectList>({ totalItemCount: 0, pageCount: 1, hasNextPage: false, hasPreviousPage: false, items: [] })
        : this.projectsService.getProjects({
            solutionSpace: this.solutionSpace,
            pageRequest: { pageNumber: 1, pageSize: 1000 },
            isPositioned: false,
            groupIds: uniqueGroupIds,
          });

    projectList$.subscribe(
      (projectEligble) => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = '400px';
        dialogConfig.maxWidth = '216px';
        const addSubject: Subject<string> = new Subject();
        const projects: Array<{ projectId: string; projectName: string }> = [];
        projects.push({ projectId: 'NOT_SET', projectName: '-- unassign from project --' });
        projectEligble.items.forEach((project) => {
          projects.push({ projectId: project.id, projectName: project.name });
        });
        dialogConfig.data = {
          numComponents: ids.length,
          selectedProject: '_none_',
          addSubject,
          projects,
        };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        addSubject.subscribe(() => {
          const componentList: BackendComponent[] = [];
          const observables: Observable<any>[] = [];
          ids.forEach((id) => {
            const comp: BackendComponent | undefined = this.components.find((cmp) => cmp.id === id);
            if (comp) componentList.push(comp);
          });
          componentList.forEach((comp: BackendComponent) => {
            if (comp?.projectId !== '00000000-0000-0000-0000-000000000000') {
              const projectId = comp.projectId;
              // remove previously set project from the current component
              observables.push(this.projectsService.removeComponent(comp.id, projectId));
            }
          });
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (dialogConfig.data.selectedProject === 'NOT_SET' && observables && observables.length > 0) {
            forkJoin(observables).subscribe(
              () => {
                this.snackbarService.openSnackBar(
                  {
                    title: 'Component project update',
                    message: `${ids.length} components successfully unassigned to project`,
                  },
                  'UPDATE'
                );
                this.dialog.closeAll();
                void this.loadData().then(() => this.updateTable());
              },
              (error: HttpErrorResponse) => {
                this.dialog.closeAll();
                this.responseHandler.error(error);
              }
            );
          } else if (observables && observables.length > 0) {
            forkJoin(observables).subscribe(
              (responses) => {
                this.componentsService
                  .addComponentsToProject(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                    dialogConfig.data.selectedProject,
                    ids
                  )
                  .subscribe({
                    next: () => {
                      this.snackbarService.openSnackBar(
                        {
                          title: 'Component project update',
                          message: `${ids.length} components successfully added to project`,
                        },
                        'UPDATE'
                      );
                      this.dialog.closeAll();
                      void this.loadData().then(() => this.updateTable());
                    },
                    error: (error: HttpErrorResponse) => {
                      this.dialog.closeAll();
                      this.responseHandler.error(error, 10000);
                    },
                  });
              },
              (error) => {
                console.error('error', error);
              },
              () => console.log('Completed')
            );
          } else
            this.componentsService
              .addComponentsToProject(
                // eslint-disable-next-line
                dialogConfig.data.selectedProject,
                ids
              )
              .subscribe({
                next: () => {
                  this.snackbarService.openSnackBar(
                    {
                      title: 'Component project update',
                      message: `${ids.length} components successfully added to project`,
                    },
                    'UPDATE'
                  );
                  this.dialog.closeAll();
                  void this.loadData().then(() => this.updateTable());
                },
                error: (error: HttpErrorResponse) => {
                  this.dialog.closeAll();
                  this.responseHandler.error(error, 10000);
                },
              });
          addSubject.unsubscribe();
        });
        this.dialog.open(SetProjectDialogComponent, dialogConfig);
      },
      (error: HttpErrorResponse) => {
        this.responseHandler.error(error);
      }
    );
  }

  openProjectDialog(): void {
    this.addToProjectDialog(this.selectedIds);
  }

  dualSolutions(): boolean {
    return this.canAccess.includes('utilization') && this.canAccess.includes('humidity');
  }

  onSearchInArea(area: Area) {
    this.componentsMenuComponent.filterComponent.applyAreaFilter(area);
  }

  onSwitchView(showMap: boolean) {
    this.showMap = showMap;
    if (showMap) {
      this.loadMapData();
    }
    this.cdr.detectChanges();
  }

  private setTableDataSource() {
    this.dataSource = this.components.map((component) => this.mapDataToTableRow(component));
  }

  private mapDataToTableRow(component: BackendComponent): TableRow {
    return {
      id: {
        value: component.id,
      },
      componentIconHealth: {
        iconLeading: statusIconFromComponentHealth(component.componentHealth),
        value: '',
      },
      componentId: {
        routerLink: this.isProjectComponentlist
          ? projectComponentLink(this.solutionSpace, component.projectId, component.id)
          : componentLink(this.solutionSpace, component.id),
        value: component.name,
      },
      componentType: {
        value: component.componentTypeDescription
          ? `${component.componentTypeName} - ${component.componentTypeDescription}`
          : component.componentTypeName,
      },
      humidity: {
        value: component.componentHealth.latestHumidityMeasurement
          ? `${Math.round(component.componentHealth.latestHumidityMeasurement)}%`
          : 'N/A',
        value2: component.componentHealth.latestHumidityThreshold
          ? ` / ${Math.round(component.componentHealth.latestHumidityThreshold)}%`
          : ' / Not set',
      },
      temperature: {
        value: component.componentHealth.latestExternalTemperatureMeasurement
          ? `${Math.round(component.componentHealth.latestExternalTemperatureMeasurement)}°C`
          : 'N/A',
      },
      positionType: {
        iconLeading: getPositionType(component.position).iconPath,
        tooltipText: getPositionType(component.position).tooltipText,
        value: '',
      },
      countryName: {
        value: component.lastKnownCountryName ?? '',
      },
      regionName: {
        value: component.lastKnownRegionName ?? '',
      },
      placeName: {
        value: component.lastKnownPlaceName ?? '',
      },
      postcodeName: {
        value: component.lastKnownPostcodeName ?? '',
      },
      utilization: {
        iconLeading: stateToUtilizationIconMap[component.state],
        value: stateToUtilizationTextMap[component.state],
      },
      daysInState: {
        value: getStateCycles(component),
      },
      turns: {
        value: component.cycles,
      },
      project: projectLink(this.solutionSpace, component.projectName, component.projectId),
      isCurrentlyInProject: currentlyInProjectIcon(component.isCurrentlyInProject),

      incident: incidentValueFromHeathComponent(component.componentHealth),
      lastCommunication: {
        value: formatDate(component.componentHealth.lastCommunication),
      },
    };
  }

  private computeColumnsToDisplay(): TableColumn[] {
    // prettier-ignore
    return [
      { name: '', key: 'componentIconHealth', indicatorIcon: true },
      { name: 'Component ID', key: 'componentId', isSortable: true, isSticky: true },
      this.isProjectComponentlist ? { name: '', key: 'isCurrentlyInProject' } : undefined,
      { name: 'Component type', key: 'componentType' },
      this.solutionSpace === SolutionSpace.Humidity ? { name: 'Humidity %', key: 'humidity', isSortable: true } : undefined,
      this.solutionSpace === SolutionSpace.Humidity ? { name: 'Temperature °C', key: 'temperature', isSortable: true } : undefined,
      { name: '', key: 'positionType', indicatorIcon: true },
      { name: 'Country', key: 'countryName' },
      { name: 'Region', key: 'regionName' },
      { name: 'Place', key: 'placeName' },
      { name: 'Postcode', key: 'postcodeName' },
      this.solutionSpace === SolutionSpace.Utilization ? { name: 'Utilization', key: 'utilization', isSortable: true } : undefined,
      this.solutionSpace === SolutionSpace.Utilization && !this.componentFilter.archived ? { name: 'Days in state', key: 'daysInState' }: undefined,
      this.solutionSpace === SolutionSpace.Utilization ? { name: 'Turns', key: 'turns' } : undefined,
      { name: 'Incident', key: 'incident' },
      !this.isProjectComponentlist ? { name: 'Project', key: 'project' } : undefined,
      { name: 'Last Comm. (UTC)', key: 'lastCommunication', isSortable: true },
    ].filter(isDefined);
  }
}
