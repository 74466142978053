/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { AuthStore } from './state/auth.store';
import { authCodeFlowConfig } from './auth.config';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { UserPermissions } from 'src/app/models/user';
import { isDefined } from 'src/app/shared/utils';
import { UserStore } from '../api/state/user.store';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private oauthService: OAuthService, private authStore: AuthStore, private userStore: UserStore) {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setStorage(localStorage);
    this.authStore.setLoading(true);
    this.login();
  }

  loadDiscoveryDocumentAndLogin(): void {
    this.oauthService.logOut();
    this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then(() => {
        this.authStore.setLoading(false);
      })
      .catch((err) => {});
  }

  login(): void {
    this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then(() => {
        this.authStore.setLoading(false);
      })
      .catch((err) => {});
    this.oauthService.setupAutomaticSilentRefresh();
  }

  logoutCognitoDirect(): void {
    this.oauthService.logOut();
    this.userStore.reset();
    this.authStore.reset();

    window.location.href = `${environment.logoutUrl}?client_id=${authCodeFlowConfig.clientId!}&logout_uri=${
      window.location.origin
    }/landing`;
  }
  get events(): Observable<OAuthEvent> {
    return this.oauthService.events;
  }
  get idToken(): string {
    return this.oauthService.getIdToken();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  get hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  get hasValidIdToken(): boolean {
    return this.oauthService.hasValidIdToken();
  }

  refresh(): void {
    void this.oauthService.refreshToken();
  }

  decodeToken(): void {
    const decodedToken = jwtDecode<UserPermissions>(this.accessToken);

    const permissionToAccessMappings: Record<string, string> = {
      'READ:WEB_APP': 'web_app',
      'READ:UTILIZATION_SOLUTION': 'utilization',
      'READ:HUMIDITY_SOLUTION': 'humidity',
      'READ:COMPONENT': 'components',
      'READ:PROJECT': 'projects',
      'READ:INCIDENT': 'incidents',
      'READ:DEVICE_CONFIG': 'settings',
      'CREATE:COMPONENT': 'component_create',
      'CREATE:COMPONENT_TYPE': 'component_types',
      'CREATE:PROJECT': 'projects_create',
    };

    const mappedAccess = decodedToken.poly_permissions
      .split(',')
      .map((x) => permissionToAccessMappings[x])
      .filter((y) => isDefined(y));

    const user = {
      userId: decodedToken.username,
      organisation: {
        organisationId: decodedToken.poly_organisation,
        canAccess: mappedAccess,
      },
    };

    this.userStore.setLoading(true);
    this.userStore.update(() => ({
      organisation: user.organisation,
    }));
    this.userStore.setLoading(false);
  }

  getAccessTokenTimeout(): number {
    const exp = this.oauthService.getAccessTokenExpiration();
    /* const d = new Date();
    d.setTime(exp); */

    return exp;
  }
  logout(): void {
    this.oauthService.logOut();
  }
}
