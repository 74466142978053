/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Organisation } from '../models/organisation';

import { UserQuery } from '../services/api/state/user.query';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent implements OnInit, OnDestroy {
  organisation?: Organisation;
  authSubscription: Subscription;
  orgSubscription: Subscription;

  constructor(private userQuery: UserQuery, private authService: AuthenticationService, private router: Router) {}
  ngOnInit(): void {
    this.authSubscription = this.authService.events.pipe(filter((e) => e.type === 'token_received')).subscribe((_) => {
      this.authService.decodeToken();
    });

    this.orgSubscription = this.userQuery.organisation$.subscribe((organisation) => {
      if (!organisation) return;

      this.organisation = organisation;
      void this.router.navigateByUrl('/landing');
    });
  }

  ngOnDestroy(): void {
    this.authSubscription && this.authSubscription.unsubscribe();
    this.orgSubscription && this.orgSubscription.unsubscribe();
  }
}
