import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SolutionSpace } from '../../models/solution';
import { ProjectsService } from 'src/app/services/api/projects.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { Project } from 'src/app/models/project';
import { PageEvent } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { UserQuery } from 'src/app/services/api/state/user.query';
import { SortDirection, ProjectsSortOrder } from 'src/app/models/page-request';

@Component({
  selector: 'app-zx-projects-overview',
  templateUrl: './zx-projects-overview.component.html',
  styleUrls: ['./zx-projects-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZxProjectsOverviewComponent implements OnInit {
  activeLink = 'utilization';
  solutionSpace: SolutionSpace;
  loaded = false;
  private paramSubscription: Subscription;

  type: string[] | undefined;
  archived = false;
  sortBy: string | undefined = 'name';
  sortDirection: string | undefined = '0';
  pageNumber = 1;
  pageSize = 9;
  totalItems = 0;
  displayedText = 'Active';
  links = [
    { id: 'utilization', label: 'Utilization', path: '../utilization' },
    { id: 'humidity', label: 'Humidity', path: '../humidity' },
  ];

  private orgSubscription: Subscription;

  constructor(
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private responseHandler: ResponseHandlerService,
    private titleService: Title,
    private userQuery: UserQuery
  ) {}

  canAccess: string[] = [];
  projects: Project[] = [];

  ngOnInit(): void {
    this.titleService.setTitle('Projects');

    this.orgSubscription = this.userQuery.organisation$.subscribe((organisation) => {
      this.canAccess = organisation?.canAccess || [];
    });

    this.paramSubscription = this.route.params.subscribe((el) => {
      if (el.utilorhumid === 'humidity') {
        this.activeLink = 'humidity';

        this.solutionSpace = SolutionSpace.Humidity;
      } else {
        this.activeLink = 'utilization';

        this.solutionSpace = SolutionSpace.Utilization;
      }
      this.loaded = false;
      this.loadProjects(1, 50, SortDirection.DESC, ProjectsSortOrder.PROJECT_NAME, '', false);
    });
  }

  uniqueComponentTypes(): { value: string; label: string }[] {
    const uniques: { value: string; label: string }[] = [];

    return uniques;
  }

  loadProjects(
    pageNumber: number,
    pageSize: number,
    sortDirection: SortDirection,
    sortOrder: ProjectsSortOrder,
    searchText: string | undefined,
    archived: boolean
  ): void {
    this.projectsService
      .getProjects({
        solutionSpace: this.solutionSpace,
        pageRequest: {
          pageNumber: pageNumber,
          pageSize: pageSize,
          sortDirection: sortDirection,
          sortOrder: sortOrder,
        },
        likeSearch: searchText,
        archived: archived,
      })
      .subscribe(
        (projects) => {
          this.totalItems = projects.totalItemCount;
          this.projects = projects.items;
          // this.componentTypes = this.uniqueComponentTypes();
          this.loaded = true;
        },
        (error: HttpErrorResponse) => this.responseHandler.error(error)
      );
  }
  getOrigin(): string {
    return document.location.pathname;
  }

  updateData(search?: string): void {
    const sortDirection: SortDirection = this.sortDirection == '0' ? SortDirection.ASC : SortDirection.DESC;
    const sortBy: ProjectsSortOrder =
      this.sortBy === 'name' ? ProjectsSortOrder.PROJECT_NAME : ProjectsSortOrder.CREATED;
    const archive = this.archived;
    const text = search ? search : undefined;
    this.loaded = false;
    this.loadProjects(this.pageNumber, this.pageSize, sortDirection, sortBy, text, archive);
  }

  dualSolutions(): boolean {
    return this.canAccess.includes('utilization') && this.canAccess.includes('humidity');
  }
  setTypeControl(typeControl: UntypedFormControl): void {
    // eslint-disable-next-line
    this.type = typeControl.value ? (typeControl.value as string[]) : undefined;
    this.updateData();
  }

  setArchiveControl(archived: boolean): void {
    if (archived) {
      this.displayedText = 'Archived';
    } else {
      this.displayedText = 'Active';
    }
    // eslint-disable-next-line
    this.archived = archived;

    this.updateData();
  }

  setSortControl(sortControl: UntypedFormControl): void {
    // eslint-disable-next-line
    this.sortBy = typeof sortControl.value == 'string' ? sortControl.value.toLocaleLowerCase() : undefined;
    this.updateData();
  }

  setSortDirectionControl(sortDirectionControl: UntypedFormControl): void {
    // eslint-disable-next-line
    this.sortDirection =
      // eslint-disable-next-line
      typeof sortDirectionControl.value == 'string' ? sortDirectionControl.value.toLocaleLowerCase() : undefined;
    this.updateData();
  }

  // pagination event
  handlePageEvent(event: PageEvent): void {
    if (event.pageIndex !== undefined) {
      this.pageNumber = event.pageIndex + 1;
    }
    this.updateData();
  }
}
