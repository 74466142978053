import { Device } from './device';
import { Incident } from './incident';
import { NumericUtilOrHumid, SolutionSpace } from './solution';
import { BackendComponent, positionType } from '../services/api/components.service';
import { IncidentType } from '../services/api/incidents.service';
import { ComponentState } from './component';
import { TableCell, TableRow } from '../shared/table/table.component';
import { Datalog } from './datalog';
import { BackendComponentActivity, BackendComponentActivityDescription } from './backend/backend-component-activity';
import { ComponentActivity } from '../shared/recent-history/recent-history.component';
import { plainToClass } from 'class-transformer';
import { isDefined } from '../shared/utils';

function statusIcon(status: IncidentType, archived = false): string {
  if (archived) return '/assets/icons/icon-16-condition-grey.svg';
  switch (status) {
    case IncidentType.BATTERY_LEVEL:
      return '/assets/icons/icon-16-alarm.svg';
    case IncidentType.HUMIDITY:
    case IncidentType.NormalizedShockEnergy:
      return '/assets/icons/icon-16-alert.svg';
    default:
      return '/assets/icons/icon-16-condition.svg';
  }
}

function statusIconProject(status: IncidentType, archived = false): string {
  if (archived) return '/assets/icons/icon-16-condition-grey.svg';
  switch (status) {
    case IncidentType.NONE:
      return '/assets/icons/icon-16-alarm.svg';
    case IncidentType.HUMIDITY:
    case IncidentType.NormalizedShockEnergy:
      return '/assets/icons/icon-16-alert.svg';
    default:
      return '/assets/icons/icon-16-condition.svg';
  }
}

function projectIncidentStatusIcon(status: IncidentType, acknowledged: boolean): string {
  return acknowledged ? '/assets/icons/icon-16-ok.svg' : statusIconProject(status);
}

function componentIncidentStatusIcon(status: IncidentType, acknowledged: boolean): string {
  return acknowledged ? '/assets/icons/icon-16-ok.svg' : statusIcon(status);
}

export function currentlyInProjectIcon(isCurrentlyInProject: boolean): TableCell {
  return isCurrentlyInProject
    ? { value: '' }
    : { value: '', iconLeading: '/assets/icons/icon-16-unlink.svg', tooltipText: 'Inactive in project' };
}

export function incidentStatus(status: IncidentType, acknowledged: boolean): { iconLeading?: string; value: string } {
  const value = incidentValue(status);
  if (acknowledged && status !== IncidentType.NONE) {
    return {
      iconLeading: '/assets/icons/icon-16-ok.svg',
      value: value.value,
    };
  } else {
    return value;
  }
}

function incidentStatusValue(status: IncidentType): { iconLeading: string; value: string } {
  switch (status) {
    case IncidentType.HUMIDITY:
      return {
        iconLeading: '/assets/icons/icon-16-humidity.svg',
        value: 'Humidity',
      };
    case IncidentType.NormalizedShockEnergy:
      return {
        iconLeading: '/assets/icons/icon-16-shock.svg',
        value: 'Shock',
      };

    case IncidentType.NONE:
      return {
        iconLeading: '/assets/icons/icon-16-no-comm.svg',
        value: 'No communication',
      };
    default:
      return {
        iconLeading: '/assets/icons/error.svg',
        value: '<error>',
      };
  }
}

export function incidentReadout(status: IncidentType, readout: number): string {
  switch (status) {
    case IncidentType.HUMIDITY:
      return `${Math.round(readout)}%`;
    case IncidentType.NormalizedShockEnergy:
      return `${Math.round(readout) === 16 || readout > 15 ? '16' : Math.round(Math.abs(readout))} g`;
    case IncidentType.BATTERY_LEVEL:
      return `${readout}%`;
    default:
      return '';
  }
}

function readoutValue(incident: Incident): string {
  switch (incident.incidentType) {
    case IncidentType.HUMIDITY:
      return `${Math.round(incident.measurementValue)}%`;
    case IncidentType.NormalizedShockEnergy:
      return `${Math.abs(incident.measurementValue).toFixed(1)} g`;
    default:
      return incident.measurementValue.toString();
  }
}

// TODO DELETE WHEN THE COMPONENT HEATH IS PROPAGATED TO THE PROJECT
export function incidentValue(status: IncidentType): { iconLeading?: string; value: string } {
  switch (status) {
    case IncidentType.HUMIDITY:
      return {
        iconLeading: '/assets/icons/icon-16-humidity.svg',
        value: 'Humidity',
      };
    case IncidentType.NormalizedShockEnergy:
      return {
        iconLeading: '/assets/icons/icon-16-shock.svg',
        value: 'NSE',
      };
    case IncidentType.BATTERY_LEVEL:
      return {
        iconLeading: '/assets/icons/icon-16-battery.svg',
        value: 'Low battery',
      };
    case IncidentType.NONE:
      return {
        value: ' ',
      };
    default:
      return {
        iconLeading: '/assets/icons/error.svg',
        value: '<error>',
      };
  }
}

export function getDatalogMaptrackState(state: ComponentState): { iconLeading: string; value: string } {
  switch (state) {
    case ComponentState.Available:
      return {
        value: 'Available',
        iconLeading: '/assets/icons/check-mark.svg',
      };

    case ComponentState.InUse:
      return {
        value: 'In use',
        iconLeading: '/assets/icons/icon-16-in-use.svg',
      };
    case ComponentState.InService:
      return {
        value: 'In service',
        iconLeading: '/assets/icons/icon-16-service-grey.svg',
      };

    case ComponentState.InReturn:
      return {
        value: 'In return',
        iconLeading: '/assets/icons/icon-16-orange-return.svg',
      };

    case ComponentState.Archived:
      return {
        value: 'Archived',
        iconLeading: '',
      };
  }
}

export function projectLink(
  solutionSpace: SolutionSpace,
  name: string,
  id: string
): { value: string; routerLink?: string } {
  if (name && name.length > 0) {
    return {
      value: name,
      routerLink: `projects/${solutionSpace}/${id}/overview`,
    };
  } else
    return {
      value: '',
    };
}

export function componentLink(solutionSpace: SolutionSpace, id: string): string {
  if (id != '00000000-0000-0000-0000-000000000000') return `components/${solutionSpace}/${id}/overview`;
  else return '';
}

export function projectComponentLink(solutionSpace: SolutionSpace, projectId: string, componentId: string): string {
  return `projects/${solutionSpace}/${projectId}/components/${componentId}/overview`;
}

export function humidityValues(humidity: number, humidityWarningValue: number): { value: string; value2: string } {
  // humidity === 0
  if (humidity === null) return { value: '', value2: '' };
  const hum = Math.round(humidity).toString();
  return { value: `${hum}% `, value2: ` / ${humidityWarningValue}%` };
}

export function shockValues(shock: number, shockThreshold: number): { value: string; value2: string } {
  // shock === 0
  if (shock === null) return { value: '', value2: '' };
  if (shock < 0) {
    // convert shock megative value to positive
    shock = shock * -1;
  }
  const sho = Math.round(shock).toString();
  return { value: `${sho}  `, value2: ` / ${shockThreshold} ` };
}

export function getPositionType(position: { lat: number; lng: number; type: positionType }): {
  iconPath: string;
  tooltipText: string;
} {
  if (position) {
    if (position.type === positionType.BASE_STATION) {
      return {
        iconPath: '/assets/icons/icon-16-antenna.svg',
        tooltipText: 'Antenna',
      };
    } else if (position.type === positionType.GNSS) {
      return {
        iconPath: '/assets/icons/icon-16-gps.svg',
        tooltipText: 'GNSS',
      };
    } else if (position && position.type === null) {
      return {
        iconPath: '/assets/icons/icon-16-no-tag.svg',
        tooltipText: 'Last known position',
      };
    }
  }
  return {
    iconPath: '/assets/icons/icon-16-no-tag.svg',
    tooltipText: 'Location not known',
  };
}

export function getStateCycles(cmp: BackendComponent): number {
  switch (cmp.state) {
    case ComponentState.Available:
      return cmp.currentCycleDaysAvailable;
    case ComponentState.InUse:
      return cmp.currentCycleDaysInUse;
    case ComponentState.InService:
      return cmp.currentCycleDaysInService;
    case ComponentState.InReturn:
      return cmp.currentCycleDaysInReturn;
    default:
      return -1;
  }
}

export function projectIncidentTableRow(incident: Incident): TableRow {
  const obj: TableRow = {
    id: { value: incident.id },
    time: { value: incident.measurementTime },
    component: {
      value: incident.componentName || '<unknown>',
      iconLeading: projectIncidentStatusIcon(incident.incidentType, incident.isAcknowledged || true),
    },
    incident: incidentStatusValue(incident.incidentType),
    readout: { value: readoutValue(incident) },
    message: { value: incident.comment || '' },
  };
  return obj;
}

export function deviceTableRow(device: Device): TableRow {
  const obj: TableRow = {
    name: { value: device.identifier.replace(/-/g, ':') },
    type: { value: device.solutionSpace === NumericUtilOrHumid.Utilization ? 'LH - Utilization' : 'LH - Humidity' },
    firmware: { value: device.firmware || '' },
    lastCommunicationTime: { value: device.lastCommunicationTime },
    componentName: {
      value: device.componentName,
      routerLink: componentLink(
        device.solutionSpace === NumericUtilOrHumid.Humidity ? SolutionSpace.Humidity : SolutionSpace.Utilization,
        device.componentId
      ),
    },
  };

  return obj;
}

export function componentIncidentTableRow(incident: Incident): TableRow {
  const obj: TableRow = {
    time: {
      value: incident.measurementTime,
    },
    incident: incidentStatus(incident.incidentType, incident.isAcknowledged),
    readout: {
      value: incidentReadout(incident.incidentType, incident.measurementValue),
    },
    created: {
      value: incident.created,
    },
    comment: {
      value: incident.comment,
    },
    action: {
      iconLeading: componentIncidentStatusIcon(incident.incidentType, incident.isAcknowledged),
      value: incident.isAcknowledged ? 'Resolved' : 'Resolve',
      linkCallback: true,
    },
    id: {
      value: incident.id,
    },
  };
  return obj;
}

export function datalogToTableRow(trackingPoints: Datalog[], solutionSpace: SolutionSpace): TableRow[] {
  const tableRowData: TableRow[] = trackingPoints.map((trackingPoint) => {
    const status = getStatusFromDatalog(trackingPoint, solutionSpace);

    const tableRow: TableRow = {
      time: {
        value: trackingPoint.time,
        iconLeading: getPositionType(trackingPoint.position).iconPath,
        tooltipText: getPositionType(trackingPoint.position).tooltipText,
      },
      incident: {
        iconLeading: incidentValue(status).iconLeading,
        value: incidentValue(status).value === 'Shock' ? `NSE` : incidentValue(status).value,
      },
      humidity: {
        value: trackingPoint.humidity ? `${Math.round(trackingPoint.humidity)}%` : '',
        value2: trackingPoint.humidityWarningValue ? ` / ${Math.round(trackingPoint.humidityWarningValue)}%` : '',
      },
    };

    if (solutionSpace === 'utilization' && isDefined(trackingPoint.state)) {
      tableRow.utilization = getDatalogMaptrackState(trackingPoint.state);
    }

    return tableRow;
  });
  return tableRowData;
}

export function componentToTableRow(solution: SolutionSpace, component: BackendComponent): TableRow {
  const tableRow: TableRow = {
    id: {
      value: component.id,
    },
    componentName: {
      value: component.name,
      iconLeading: getPositionType(component.position).iconPath,
      tooltipText: getPositionType(component.position).tooltipText,
    },
    incident: {
      iconLeading: component.componentHealth.activeShockIncident ? '/assets/icons/icon-16-shock.svg' : '',
      value: component.componentHealth.activeShockIncident ? 'Acceleration' : '',
    },
    isCurrentlyInProject: currentlyInProjectIcon(component.isCurrentlyInProject),
  };

  if (solution === 'humidity') {
    tableRow.humidity = {
      value: component.componentHealth.activeHumidityIncidentValue
        ? `${Math.round(component.componentHealth.activeHumidityIncidentValue).toString()}% /`
        : 'NaN /',
      value2: component.componentHealth.latestHumidityThreshold
        ? `${component.componentHealth.latestHumidityThreshold.toString()}%`
        : 'Not set',
    };
    if (component.componentHealth.activeHumidityIncident) {
      tableRow.componentName.iconLeading = '/assets/icons/icon-16-alert.svg';
    }

    if (component.componentHealth.activeHumidityIncident && !component.componentHealth.activeShockIncident) {
      tableRow.incident = { iconLeading: '/assets/icons/icon-16-humidity.svg', value: 'Humidity' };
    }
  } else {
    tableRow.utilization = getDatalogMaptrackState(component.state);
  }
  return tableRow;
}

export function getStatusFromDatalog(datalog: Datalog, solution: SolutionSpace): IncidentType {
  if (
    datalog.humidity &&
    datalog.humidityWarningValue &&
    datalog.humidity > datalog.humidityWarningValue &&
    solution === SolutionSpace.Humidity
  ) {
    return IncidentType.HUMIDITY;
  } else if (datalog.shock > datalog.shockThreshold) {
    return IncidentType.NormalizedShockEnergy;
  }
  return IncidentType.NONE;
}

export function parseSolutionSpace(solution: string): SolutionSpace {
  switch (solution) {
    case 'humidity':
      return SolutionSpace.Humidity;
    case 'utilization':
      return SolutionSpace.Utilization;
    default:
      throw new Error(`Could not parse "${solution}" to a solution space`);
  }
}

export function datalogMapper(datalog: Datalog): Datalog {
  if (datalog.radarMeasurementInMillimeter == 'Infinity') {
    datalog.radarMeasurementInMillimeter = Infinity;
  }

  datalog.time = new Date(datalog.time);
  return datalog;
}

export function componentActivityMapper(activities: BackendComponentActivity[]): ComponentActivity[] {
  if (activities.length === 0) return [];
  return activities
    .map((a) => plainToClass(BackendComponentActivity, a))
    .map((activity) => {
      return {
        text: activity.getText(),
        timestamp: activity.occurredAt,
        activity: activity.getActivityType(),
      };
    });
}
