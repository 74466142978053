import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { SortDirection } from 'src/app/services/api/components.service';
import { SolutionSpace } from 'src/app/models/solution';
import { componentIncidentTableRow } from 'src/app/models/transforms';
import { Incident, IncidentsPageable, IncidentUpdateState } from 'src/app/models/incident';
import { HttpErrorResponse } from '@angular/common/http';
import { IncidentDialogComponent } from './incident-dialog/incident-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReopenDialogComponent } from './reopen-dialog/reopen-dialog.component';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { IncidentAcknowledgeMultipleComponent } from './incident-acknowledge-multiple/incident-acknowledge-multiple.component';
import { IncidentsService, IncidentType } from 'src/app/services/api/incidents.service';
import { PageEvent } from '@angular/material/paginator';
import { ResponseObj } from 'src/app/models/errorHandling';
import { Sort } from '@angular/material/sort';
import { TableColumn, TableRow } from 'src/app/shared/table/table.component';
import { IncidentsSortOrder, PageRequest } from 'src/app/models/page-request';
import { IncidentFilter } from 'src/app/models/incident-filter';

@Component({
  selector: 'app-component-incidents',
  templateUrl: './component-incidents.component.html',
  styleUrls: ['./component-incidents.component.scss'],
})
export class ComponentIncidentsComponent implements OnInit {
  @Input() solutionSpace: SolutionSpace;
  @Input() incidents$: Observable<IncidentsPageable>;

  @Output() changeIncidentsFilter = new EventEmitter<IncidentFilter>();
  @Output() changePagination = new EventEmitter<PageRequest<IncidentsSortOrder>>();

  filter: IncidentFilter = {
    incidentTypes: [IncidentType.HUMIDITY, IncidentType.NormalizedShockEnergy],
    isAcknowledged: false,
  };

  pagination: PageRequest<IncidentsSortOrder> = {
    pageNumber: 1,
    pageSize: 50,
    sortOrder: IncidentsSortOrder.MEASUREMENT_TIME,
    sortDirection: SortDirection.ASC,
  };

  resolvedFilterText = 'View resolved';
  viewResolved = false;
  iconResolve = '/assets/icons/view.svg';
  incidentTypeText = 'Incident types';
  incidentTypeIcon = '/assets/icons/icon-16-alert.svg';

  incidents: Incident[] = [];
  dataSource: TableRow[] = [];
  selectedIncidents: Incident[] = [];

  totalItemCount = 0;
  isFilterClicked = false;

  displayedColumns: TableColumn[] = [
    { name: 'Measurements time (UTC)', key: 'time', isSortable: true },
    { name: 'Incidents', key: 'incident' },
    { name: 'Measurement', key: 'readout' },
    { name: 'Creation time (UTC)', key: 'created', isSortable: true },
    { name: 'Comment', key: 'comment' },
    { name: 'Action', key: 'action' },
  ];

  isIncidentsLoaded = false;
  shouldResolveAll = true;

  incidentTypes = [
    { value: 'SHOCK', label: 'NSE incidents', icon: '/assets/icons/icon-16-shock.svg' },
    { value: 'HUMIDITY', label: 'Humidity', icon: '/assets/icons/icon-16-humidity.svg' },
    { value: 'ALL', label: 'All incidents', icon: '/assets/icons/icon-16-alert.svg' },
  ];
  constructor(
    private dialog: MatDialog,
    private responseHandler: ResponseHandlerService,
    private snackBar: SnackbarService,
    private incidentSerivce: IncidentsService
  ) {}

  ngOnInit(): void {
    this.changeIncidentsFilter.emit(this.filter);
    this.changePagination.emit(this.pagination);

    this.incidents$.subscribe({
      next: (incidents) => {
        this.totalItemCount = incidents.totalItemCount;
        this.incidents = incidents.items;
        this.dataSource = this.incidents.map((componentIncident) => componentIncidentTableRow(componentIncident));
        this.isIncidentsLoaded = true;
      },
      error: (error: HttpErrorResponse) => {
        this.responseHandler.error(error);
      },
    });
  }

  onSortChange(event: Sort): void {
    this.pagination.sortDirection = event.direction === 'asc' ? SortDirection.ASC : SortDirection.DESC;
    this.pagination.sortOrder =
      event.active === 'time' ? IncidentsSortOrder.MEASUREMENT_TIME : IncidentsSortOrder.CREATED;

    this.isIncidentsLoaded = false;
    this.changePagination.emit(this.pagination);
  }

  acknowledgeMuntipleIncidents() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '20vh';
    dialogConfig.minWidth = '27vw';
    dialogConfig.data = { counts: this.selectedIncidents.length };
    const dialog = this.dialog.open(IncidentAcknowledgeMultipleComponent, dialogConfig);
    dialog.afterClosed().subscribe((result: { comment: string }) => {
      if (result) {
        const observables: Observable<any>[] = [];
        this.selectedIncidents.forEach((incident: Incident) => {
          observables.push(
            this.incidentSerivce.updateIncident(incident.id, IncidentUpdateState.Acknowledge, result.comment)
          );
        });
        forkJoin(observables).subscribe(
          (result) => {
            // trigger new fetch by sending same filter again
            this.isIncidentsLoaded = false;
            this.changeIncidentsFilter.emit(this.filter);

            this.snackBar.openSnackBar(
              {
                title: `Incidents Resolved`,
                message: `${this.selectedIncidents.length} Incidents have been resolved`,
              },
              'ACKNOWLEGDE'
            );
            this.selectedIncidents = [];
            this.shouldResolveAll = this.incidents && this.incidents.length > 0 ? true : false;
          },
          (error: HttpErrorResponse) => {
            this.responseHandler.error(error);
          }
        );
      }
    });
  }

  setIncidentType(type: { label: string; value: string }): void {
    switch (type.value) {
      case 'ALL':
        {
          this.filter.incidentTypes = [IncidentType.HUMIDITY, IncidentType.NormalizedShockEnergy];
          this.incidentTypeIcon = '/assets/icons/icon-16-alert.svg';
          this.incidentTypeText = 'Incident types';
        }
        break;
      case 'HUMIDITY':
        {
          this.filter.incidentTypes = [IncidentType.HUMIDITY];
          this.incidentTypeIcon = '/assets/icons/icon-16-humidity.svg';
          this.incidentTypeText = 'Humidity';
        }
        break;
      case 'SHOCK':
        {
          this.filter.incidentTypes = [IncidentType.NormalizedShockEnergy];
          this.incidentTypeIcon = '/assets/icons/icon-16-shock.svg';
          this.incidentTypeText = 'NSE incident';
        }
        break;
    }

    this.isIncidentsLoaded = false;
    this.changeIncidentsFilter.emit(this.filter);
  }
  resolveAll() {
    this.selectedIncidents = this.incidents;
    this.acknowledgeMuntipleIncidents();
  }

  bulkSelection(selectedIncidents: TableRow[]) {
    const selectedIds = selectedIncidents.map((incident) => incident.id.value as string);
    this.selectedIncidents = this.incidents.filter((incident) => selectedIds.includes(incident.id));

    if (this.selectedIncidents && this.selectedIncidents.length > 0 && !this.filter.isAcknowledged) {
      this.shouldResolveAll = false;
    } else {
      this.shouldResolveAll = true;
    }
  }

  setIncidentStatus(): void {
    this.viewResolved = !this.viewResolved;
    if (this.viewResolved) {
      this.filter.isAcknowledged = true;
      this.resolvedFilterText = 'Hide resolved';
      this.iconResolve = '/assets/icons/hide.svg';
    } else {
      this.filter.isAcknowledged = false;
      this.resolvedFilterText = 'View resolved';
      this.iconResolve = '/assets/icons/view.svg';
    }

    this.isIncidentsLoaded = false;
    this.changeIncidentsFilter.emit(this.filter);
  }

  handlePaginationEvent(event: PageEvent): void {
    const { pageIndex: pageNumber, pageSize } = event;
    if (this.pagination.pageNumber === pageNumber && this.pagination.pageSize === pageSize) return;

    if (this.pagination.pageNumber !== pageNumber) {
      this.pagination.pageNumber = pageNumber;
    }

    if (this.pagination.pageSize !== pageSize) {
      this.pagination.pageSize = pageSize;
    }

    this.isIncidentsLoaded = false;
    this.changePagination.emit(this.pagination);
  }

  isHumidity(): boolean {
    return this.solutionSpace === SolutionSpace.Humidity;
  }

  openAcknowledgeDialog(incident: Incident): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '20vh';
    dialogConfig.minWidth = '27vw';
    dialogConfig.data = incident;
    const dialogRef = this.dialog.open(IncidentDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      (
        confirmed: null | {
          action: IncidentUpdateState;
          shelfTime: string;
          comment: string;
        }
      ) => {
        if (confirmed !== null) {
          this.isIncidentsLoaded = false;
          this.incidentSerivce.updateIncident(incident.id, confirmed.action, confirmed.comment).subscribe(
            () => {
              // trigger new fetch by sending same filter again
              this.isIncidentsLoaded = false;
              this.changeIncidentsFilter.emit(this.filter);

              const obj: ResponseObj = {
                title: 'Incident state updated',
                message: ' The incident has been successfully updated',
              };
              this.snackBar.openSnackBar(obj, 'UPDATE');
            },
            (error: HttpErrorResponse) => this.responseHandler.error(error)
          );
        }
      }
    );
  }

  openReopenDialog(incident: Incident) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '10vh';
    dialogConfig.minWidth = '16vw';
    dialogConfig.data = incident;
    const dialogRef = this.dialog.open(ReopenDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((confirmed: number | null) => {
      if (confirmed !== null) {
        this.isIncidentsLoaded = false;
        this.incidentSerivce.updateIncident(incident.id, IncidentUpdateState.Open, '').subscribe(
          () => {
            // trigger new fetch by sending same filter again
            this.isIncidentsLoaded = false;
            this.changeIncidentsFilter.emit(this.filter);

            const obj: ResponseObj = {
              title: 'Incident state updated',
              message: ' The incident has been successfully updated',
            };
            this.snackBar.openSnackBar(obj, 'ACKNOWLEGDE');
          },
          (error: HttpErrorResponse) => this.responseHandler.error(error)
        );
      }
    });
  }

  openIncidentDialog(selectedRow: TableRow): void {
    const incident = this.incidents.find((incident) => incident.id === selectedRow?.id.value);

    if (!incident) {
      const obj: ResponseObj = {
        title: 'Error',
        message: 'Selected element does not exist, please refresh the page.',
      };
      this.snackBar.openSnackBar(obj, 'ERROR');
      return;
    }

    if (incident.isAcknowledged) {
      this.openReopenDialog(incident);
    } else {
      this.openAcknowledgeDialog(incident);
    }
  }
}
