import { Type } from 'class-transformer';

export class ComponentHighlights {
  isAvailable: boolean;
  daysInUseCurrent: number;
  daysAvailableCurrent: number;
  @Type(() => Date)
  lastUtilizationChange: Date;
  daysInFleet: number;
  daysInUseTotal: number;
  availableTotal: number;
}
