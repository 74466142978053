import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { take, map } from 'rxjs';
import { UserQuery } from '../services/api/state/user.query';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
})
export class LandingpageComponent implements OnInit {
  constructor(private authService: AuthenticationService, private router: Router, private userQuery: UserQuery) {}

  ngOnInit(): void {
    this.userQuery.organisation$
      .pipe(
        take(1),
        map((org) => org?.canAccess || [])
      )
      .subscribe({
        next: (canAccess) => {
          if (!this.authService.hasValidAccessToken) {
            this.authService.loadDiscoveryDocumentAndLogin();
          } else {
            void this.router.navigateByUrl(this.homeRoute(canAccess));
          }
        },
      });
  }

  homeRoute(canAccess: string[]): string {
    if (!canAccess.includes('web_app')) {
      return '/forbidden';
    }

    if (canAccess.includes('components')) {
      if (canAccess.includes('utilization')) {
        return '/components/utilization';
      } else {
        return '/components/humidity';
      }
    }

    if (canAccess.includes('projects')) {
      if (canAccess.includes('utilization')) {
        return '/projects/utilization';
      } else {
        return '/projects/humidity';
      }
    }
    return '/forbidden';
  }
}
