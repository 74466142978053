import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ZxProjectsModule } from './zx-projects/zx-projects.module';
import { ZxComponentsModule } from './zx-components/zx-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { CallbackComponent } from './callback/callback.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { AuthStore } from './services/authentication/state/auth.store';
import { UserStore } from './services/api/state/user.store';
import { UserQuery } from './services/api/state/user.query';
import { AuthQuery } from './services/authentication/state/auth.query';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { CookieModule } from 'ngx-cookie';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AdminModule } from './admin/admin.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CustomInterceptorService } from './services/authentication/custom-interceptor.service';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [AppComponent, CallbackComponent, SideMenuComponent, LandingpageComponent, ErrorpageComponent],
  imports: [
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: false,
      },
    }),
    MatProgressSpinnerModule,
    MatCardModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    AdminModule,
    ZxProjectsModule,
    ZxComponentsModule,
    FormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    HttpClientModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatTreeModule,
    MatTooltipModule,
    MatMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatSidenavModule,
    MatDialogModule,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    CookieModule.forRoot(),
    ReactiveFormsModule,
    MatStepperModule,
    MatSnackBarModule,
    MatPaginatorModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptorService,
      multi: true,
    },
    UserQuery,
    AuthQuery,
    AuthStore,
    UserStore,
    OAuthService,
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
