<a class="nav-cancel" (click)="clickNav()">
  <svg-icon src="{{ iconPath }}"></svg-icon>
  <span>{{ navText | translate }}</span>
</a>
<div class="stepper-container">
  <header>
    <h3 class="step-header">{{ stepHeader | translate }}</h3>
  </header>
  <mat-horizontal-stepper [linear]="project === undefined" class="stepper" #projectStepper>
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>

    <mat-step [completed]="project !== undefined" label="{{ 'project.FORM.CREATE_PROJECT' | translate }}">
      <form class="mat-mt-l">
        <div class="form-field-container" *ngIf="doubleSolutionAccess">
          <p class="form-field-header">{{ "project.FORM.SOLUTION" | translate }}</p>
          <app-select-field
            class="select-field inverted"
            (selectionChange)="form.updateSolution($event)"
            [disabled]="!!project"
            [optionArray]="[
              { value: 'utilization', label: 'Utilization monitoring' },
              { value: 'humidity', label: 'Humidity monitoring' }
            ]"
            [defaultChoice]="form.solution"
          ></app-select-field>
        </div>

        <div class="form-field-container">
          <p class="form-field-header">
            {{ "SHARED.NAME" | translate }}<span *ngIf="!form.nameValidated()" class="required">*</span>
          </p>
          <app-input-field
            class="input-name input-field inverted"
            (valueChanged)="form.updateName($event)"
            [defaultValue]="form.name"
          ></app-input-field>
        </div>
        <div class="form-field-container">
          <p class="form-field-header">{{ "SHARED.DESCRIPTION" | translate }}</p>
          <app-input-field
            class="input-name input-field inverted"
            (valueChanged)="form.updateDescription($event)"
            [defaultValue]="form.description"
          ></app-input-field>
        </div>

        <div class="form-field-container margin-last-field">
          <p class="form-field-header">
            {{ "SHARED.GROUP" | translate }}<span *ngIf="!form.groupValidated()" class="required">*</span>
          </p>
          <app-select-field
            class="select-field inverted"
            (selectionChange)="form.updateGroup($event)"
            [disabled]="!!project || !(groups && groups.length > 1)"
            [optionArray]="groupValues"
            [defaultChoice]="form.group"
          ></app-select-field>
        </div>
        <hr />
        <div class="button-container create">
          <mat-checkbox *ngIf="!project" color="primary" [formControl]="skipNextControl">
            {{ "project.FORM.SKIP_STEPS" | translate }}
          </mat-checkbox>
          <button
            class="btn-red button-margin button-left-align"
            [disabled]="project.archived"
            mat-flat-button
            color="warning"
            (click)="openArchiveDialog()"
            *ngIf="project !== undefined"
          >
            {{ "project.FORM.ARCHIVE_PROJECT" | translate }}
          </button>
          <button
            *ngIf="project === undefined"
            mat-flat-button
            (click)="onSubmit()"
            color="primary"
            [disabled]="!form.valid() || loading"
            [class.spinner]="loading"
            class="btn-submit button-margin"
          >
            {{ "project.FORM.CREATE_PROJECT" | translate }}
          </button>
          <button
            *ngIf="project !== undefined"
            mat-flat-button
            class="button-margin"
            (click)="onSubmit()"
            color="primary"
            [disabled]="!form.valid() || form.pristine()"
          >
            {{ "project.FORM.UPDATE_PROJECT" | translate }}
          </button>
          <button mat-flat-button [ngClass]="'btn-grey'" *ngIf="!!project" style="margin-left: 8px" matStepperNext>
            {{ "project.FORM.PREFERENCES" | translate }}
            <mat-icon svgIcon="poly-chevron-right"></mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Preferences">
      <div class="mat-mt-l">
        <app-preferences
          [currentPreferences]="preferencesData"
          [solution]="perferenceSolution"
          [enabled]="true"
        ></app-preferences>
      </div>
      <div class="button-container preferences">
        <button mat-flat-button style="margin-right: 10px" [ngClass]="'btn-grey left'" matStepperPrevious>
          <mat-icon svgIcon="poly-chevron-left"></mat-icon>
          {{ "project.FORM.EDIT_PROJECT" | translate }}
        </button>

        <button
          mat-flat-button
          (click)="updatePrefs()"
          color="primary"
          class="right mat-mr-l"
          [class.spinner]="preferencesUpdating"
          [disabled]="preferencesUpdating"
        >
          {{ (preferencesUpdating ? "project.FORM.SUBMITTING" : "project.FORM.SAVE_CHANGES") | translate }}
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
