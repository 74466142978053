import { Preferences } from './preferences.model';
import { ComponentType } from './component-type';
import { ComponentHealth } from './component-health';
import { positionType } from '../services/api/components.service';
export enum ComponentState {
  Available,
  InUse,
  InReturn,
  InService,
  Archived,
}

export const stateToUtilizationTextMap = {
  [ComponentState.Available]: 'Available',
  [ComponentState.InUse]: 'In use',
  [ComponentState.InReturn]: 'In return',
  [ComponentState.InService]: 'In service',
  [ComponentState.Archived]: 'Archived',
};

export const stateToUtilizationIconMap = {
  [ComponentState.Available]: '/assets/icons/check-mark.svg',
  [ComponentState.InUse]: '/assets/icons/icon-16-in-use.svg',
  [ComponentState.InReturn]: '/assets/icons/icon-16-orange-return.svg',
  [ComponentState.InService]: '/assets/icons/icon-16-service-grey.svg',
  [ComponentState.Archived]: '/assets/icons/icon-16-archive.svg',
};

export class ZXComponent {
  id: string;
  name: string;
  //project: string; // projectId / projectName// DEPRECATED!!
  projectName: string;
  projectId: string;
  type: string | ComponentType;
  //device: string; // MAC address
  deviceId: string;
  deviceIdentifier: string; // MAC Address
  deviceType: string;
  groupId?: string; // { id: stringgroup
  groupName: string;
  componentHealth: ComponentHealth;
  humidity: number;
  humidityThreshold: number;
  readout?: number;
  batteryLevel?: number;
  position: { lat: number; lng: number; type: positionType };
  archived: boolean;
  unlinked: boolean;
  inheritPreferencesFromProject = true;
  preferences?: Preferences;
  state: ComponentState;

  public constructor(init?: Partial<ZXComponent>) {
    Object.assign(this, init);
  }

  public get humidityAboveThreshold(): boolean {
    return this.componentHealth.activeHumidityIncident;
    //return this.humidity > this.preferences.humidityWarningValue;
  }
}
