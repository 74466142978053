import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { projectIncidentTableRow } from 'src/app/models/transforms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';
import { plainToClass } from 'class-transformer';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { IncidentType } from 'src/app/services/api/incidents.service';
import { ProjectsService } from 'src/app/services/api/projects.service';
import { Incident } from 'src/app/models/incident';
import { PageEvent } from '@angular/material/paginator';
import { TableColumn, TableRow } from 'src/app/shared/table/table.component';
import { Sort } from '@angular/material/sort';
import { IncidentsSortOrder, SortDirection } from 'src/app/models/page-request';

@Component({
  selector: 'app-zx-project-details-incidents',
  templateUrl: './zx-project-details-incidents.component.html',
  styleUrls: ['./zx-project-details-incidents.component.scss'],
})
export class ZxProjectDetailsIncidentsComponent implements OnInit, OnDestroy {
  solutionSpace: SolutionSpace;
  loaded = false;
  isFilterClicked = false;
  incidentTypeText = 'Incident types';
  loadedIncidentTypes: IncidentType[];
  incidentTypes = [
    { value: 'SHOCK', label: 'NSE incident', icon: '/assets/icons/icon-16-shock.svg' },
    { value: 'HUMIDITY', label: 'Humidity', icon: '/assets/icons/icon-16-humidity.svg' },
    { value: 'ALL', label: 'All incidents', icon: '/assets/icons/icon-16-alert.svg' },
  ];
  iconResolve = '/assets/icons/view.svg';
  resolvedFilterText = 'View resolved';
  viewResolved = false;
  incidentTypeIcon = '/assets/icons/icon-16-alert.svg';

  private routeSub: Subscription | undefined;
  projectId = '';
  incidents: TableRow[];

  displayedColumns: TableColumn[] = [
    { name: 'Measurements time', key: 'time', isSortable: true },
    { name: 'Component', key: 'component' },
    { name: 'Incidents', key: 'incident' },
    { name: 'Readout', key: 'readout' },
    { name: 'Message', key: 'message' },
  ];
  searchControl = new UntypedFormControl();
  incidentStatus = false;
  sortBy: IncidentsSortOrder = IncidentsSortOrder.MEASUREMENT_TIME;
  sortDirection: SortDirection = SortDirection.ASC;

  // paginations
  pageNumber = 1;
  pageSize = 50;
  totalItemCount = 0;
  incidentType: Array<IncidentType> = [IncidentType.HUMIDITY, IncidentType.NormalizedShockEnergy];

  constructor(
    private route: ActivatedRoute,
    private responseHandlerService: ResponseHandlerService,
    private projectService: ProjectsService
  ) {}

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe((el) => {
      if (el.utilorhumid === 'humidity') {
        this.solutionSpace = SolutionSpace.Humidity;
      } else {
        this.solutionSpace = SolutionSpace.Utilization;
      }
      this.projectId = String(el['id']);

      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  setIncidentStatus(): void {
    this.viewResolved = !this.viewResolved;
    if (this.viewResolved) {
      this.incidentStatus = true;
      this.resolvedFilterText = 'Hide resolved';
      this.iconResolve = '/assets/icons/hide.svg';
    } else {
      this.incidentStatus = false;
      this.resolvedFilterText = 'View resolved';
      this.iconResolve = '/assets/icons/view.svg';
    }
    this.loadData();
  }

  setIncidentType(type: { label: string; value: string }): void {
    switch (type.value) {
      case 'ALL':
        {
          this.incidentType = [IncidentType.HUMIDITY, IncidentType.NormalizedShockEnergy];
          this.incidentTypeIcon = '/assets/icons/icon-16-alert.svg';
          this.incidentTypeText = 'All incidents';
        }
        break;
      case 'HUMIDITY':
        {
          this.incidentType = [IncidentType.HUMIDITY];
          this.incidentTypeIcon = '/assets/icons/icon-16-humidity.svg';
          this.incidentTypeText = 'Humidity';
        }
        break;
      case 'SHOCK':
        {
          this.incidentType = [IncidentType.NormalizedShockEnergy];
          this.incidentTypeIcon = '/assets/icons/icon-16-shock.svg';
          this.incidentTypeText = 'NSE incident';
        }
        break;
    }

    this.loadData();
  }

  loadData(): void {
    this.loaded = false;
    this.projectService
      .getIncidents(
        this.projectId,
        {
          incidentTypes: this.incidentType,
          isAcknowledged: this.incidentStatus,
        },
        {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          sortOrder: this.sortBy,
          sortDirection: this.sortDirection,
        }
      )
      .subscribe({
        next: (incidents) => {
          this.totalItemCount = incidents.totalItemCount;
          this.incidents = incidents.items.map((i) => projectIncidentTableRow(plainToClass(Incident, i)));
          this.loaded = true;
        },
        error: (error: HttpErrorResponse) => {
          this.responseHandlerService.error(error);
        },
      });
  }

  handlePaginationEvent(event: PageEvent): void {
    const { pageIndex, pageSize } = event;
    if (this.pageNumber === pageIndex && this.pageSize === pageSize) return;

    if (this.pageNumber !== pageIndex) {
      this.pageNumber = pageIndex;
    }
    if (this.pageSize !== pageSize) {
      this.pageSize = pageSize;
    }
    this.loadData();
  }

  sortColumns(event: Sort): void {
    this.sortDirection = event.direction === 'asc' ? SortDirection.ASC : SortDirection.DESC;
    this.loadData();
  }
  isHumidity(): boolean {
    return this.solutionSpace === SolutionSpace.Humidity;
  }
}
