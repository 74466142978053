import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Subscription } from 'rxjs';
import { ComponentHighlights } from 'src/app/models/component-highlights';
import { SolutionSpace } from 'src/app/models/solution';
import { ComponentsService } from 'src/app/services/api/components.service';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';

@Component({
  selector: 'app-zx-component-highlight-card',
  templateUrl: './zx-component-highlight-card.component.html',
  styleUrls: ['./zx-component-highlight-card.component.scss'],
})
export class ZxComponentHighlightCardComponent implements OnInit {
  @Input() isAvailable = false;
  private paramSubscription: Subscription;
  solutionSpace: SolutionSpace;
  componentId: string;

  highlights: ComponentHighlights | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private componentsService: ComponentsService,
    private responseHandler: ResponseHandlerService
  ) {}

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe((el) => {
      if (el.utilorhumid === 'humidity') {
        this.solutionSpace = SolutionSpace.Humidity;
      } else {
        this.solutionSpace = SolutionSpace.Utilization;
      }
      this.componentId = el.id as string;

      this.updateData();
    });
  }

  getInfoMap(): { label: string; value: string | Date; icon?: string }[] {
    if (this.highlights === undefined) {
      return [];
    }

    const highlights = this.highlights;

    const availabilityInfo = (): {
      label: string;
      value: string | Date;
      icon?: string;
    } => {
      if (highlights.isAvailable) {
        return {
          label: 'component.daysavailablecurrent',
          // value: `${highlights.daysAvailableCurrent} days`,
          value: '?',
        };
      } else {
        return {
          label: 'component.daysinusecurrent',
          // value: `${highlights.daysInUseCurrent} days`,
          value: '?',
        };
      }
    };

    return [
      availabilityInfo(),
      {
        label: 'component.utilizationchange',
        // value: formatShortDate(highlights.lastUtilizationChange),
        value: '?',
      },
      {
        label: 'component.daysinfleet',
        // value: `${highlights.daysInFleet} days`,
        value: '?',
      },
      {
        label: 'component.daysinusetotal',
        // value: `${highlights.daysInUseTotal} days`,
        value: '?',
      },
      {
        label: 'component.availabletotal',
        // value: `${highlights.availableTotal} days`,
        value: '?',
      },
    ];
  }

  updateData(): void {
    this.componentsService.getComponentHighlights(this.componentId).subscribe(
      (highlights) => {
        this.highlights = plainToClass(ComponentHighlights, highlights);
      },
      (error: HttpErrorResponse) => {
        this.responseHandler.error(error);
        this.highlights = {
          isAvailable: false,
          daysAvailableCurrent: 61,
          daysInUseCurrent: 6,
          lastUtilizationChange: new Date(2020, 11, 21),
          daysInFleet: 369,
          daysInUseTotal: 292,
          availableTotal: 32,
        };
      }
    );
  }
}
