/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ComponentState, ZXComponent } from 'src/app/models/component';
import { SolutionSpace } from '../../models/solution';
import { Datalog } from 'src/app/models/datalog';
import { Preferences } from 'src/app/models/preferences.model';
import {
  BackendComponentActivity,
  BackendComponentActivityDescription,
} from 'src/app/models/backend/backend-component-activity';
import { ComponentHighlights } from 'src/app/models/component-highlights';
import { environment } from 'src/environments/environment';
import { Measurement, MeasurementsType } from 'src/app/models/measurement';
import { ComponentHealth } from 'src/app/models/component-health';
import { HistoricalUtilStatistic } from 'src/app/models/historicalUtilStatistic';
import { ComponentTypeList } from 'src/app/models/component-type-list';
import { Country, CountryLocations, LocationInfo, LocationType } from 'src/app/models/locations';
import { isDefined } from 'src/app/shared/utils';
import { UserService } from './user.service';
import { datalogMapper } from 'src/app/models/transforms';
import { PageRequest, ComponentsSortOrder, ComponentTypesSortOrder } from 'src/app/models/page-request';

export enum positionType {
  GNSS,
  BASE_STATION,
}
export class BackendComponent {
  id: string;
  name: string;
  groupName: string;
  groupId: string;
  projectId: string;
  projectName: string;
  projectDescription: string;
  isCurrentlyInProject: boolean;
  componentTypeId: string;
  componentTypeName: string;
  componentTypeDescription: string;
  deviceId: string;
  deviceIdentifier: string;
  deviceType: string;
  position: { lat: number; lng: number; type: positionType };
  archived: boolean;
  componentHealth: ComponentHealth;
  state: ComponentState;

  lastKnownCountryId: string;
  lastKnownCountryName: string;
  lastKnownRegionId: string;
  lastKnownRegionName: string;
  lastKnownPlaceId: string;
  lastKnownPlaceName: string;
  lastKnownPostcodeId: string;
  lastKnownPostcodeName: string;

  cycles: number;
  currentCycleDaysAvailable: number;
  currentCycleDaysInUse: number;
  currentCycleDaysInReturn: number;
  currentCycleDaysInService: number;
}

export class BackendComponentList {
  items: BackendComponent[];
  totalItemCount: number;
  pageCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export class BackendCreateComponent {
  solution?: string;
  group?: string;
  name?: string;
  type?: string;
  devices: { id: string; identifier: string }[];
}

export class BackendUpdateComponent {
  name?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ComponentsService {
  componentsUrl: string;

  constructor(private http: HttpClient, userService: UserService) {
    this.componentsUrl = environment.api + `/api/${userService.getOrganisationId()}/component/`;
  }

  get apiUrl(): string {
    return environment.api + `/api/`;
  }

  getComponent(solutionSpace: SolutionSpace, componentId: string): Observable<ZXComponent> {
    return this.http.get<ZXComponent>(`${this.componentsUrl}${solutionSpace}/${componentId}`);
  }

  getComponentDatalog(
    solutionSpace: SolutionSpace,
    componentId: string,
    startDate: Date,
    endDate: Date
  ): Observable<Datalog[]> {
    let params = new HttpParams();
    if (startDate !== undefined) {
      params = params.set('StartTimeUtc', startDate.toISOString());
    }
    if (endDate !== undefined) {
      params = params.set('StopTimeUtc', endDate.toISOString());
    }
    return this.http
      .get<Datalog[]>(`${this.componentsUrl}${solutionSpace}/${componentId}/datalog?${params.toString()}`)
      .pipe(
        map((datalogs) => {
          datalogs.map((datalog) => datalogMapper(datalog));

          return datalogs;
        })
      );
  }

  getComponentActivity(
    componentId: string,
    limit?: number,
    descriptions?: Array<BackendComponentActivityDescription>
  ): Observable<BackendComponentActivity[]> {
    let params = new HttpParams();
    if (limit !== undefined) {
      params = params.set('limit', limit.toString());
    }
    if (descriptions !== undefined) {
      descriptions.forEach((desc) => {
        params = params.append('descriptions', desc);
      });
    }
    return this.http.get<BackendComponentActivity[]>(
      `${this.componentsUrl}${componentId}/activity?${params.toString()}`
    );
  }

  getComponentHighlights(componentId: string): Observable<ComponentHighlights> {
    return this.http.get<ComponentHighlights>(`${this.componentsUrl}utilization/${componentId}/highlights`);
  }

  createComponent(componentCreate: BackendCreateComponent): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(this.componentsUrl, componentCreate);
    // eslint-disable-next-line @typescript-eslint/unbound-method
  }

  substituteDevice(componentId: string, deviceId: string): Observable<Object> {
    const payload = { newDeviceId: deviceId };
    return this.http.post(`${this.componentsUrl}${componentId}/SubstituteDevice`, payload);
  }

  updateComponent(componentId: string, componentUpdate: BackendUpdateComponent): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.componentsUrl}${componentId}`, componentUpdate);
  }

  createComponentType(
    solutionSpace: SolutionSpace,
    name: string,
    mindistance: number,
    maxdistance: number,
    description?: string
  ): Observable<Object> {
    const body: {
      componentTypeName: string;
      distanceRangeLowInMillimeters: number;
      distanceRangeHighInMillimeters: number;
      componentTypeDescription?: string;
    } = {
      componentTypeName: name,
      distanceRangeLowInMillimeters: mindistance,
      distanceRangeHighInMillimeters: maxdistance,
    };
    if (description !== undefined) {
      body.componentTypeDescription = description;
    }
    return this.http.post<{ typeId: string }>(`${this.componentsUrl}${solutionSpace}/Type`, body);
  }

  updateComponentType(
    solutionSpace: SolutionSpace,
    componentTypeId: string,
    name: string,
    mindistance: number,
    maxdistance: number,
    description?: string
  ): Observable<Object> {
    const body: {
      componentTypeName: string;
      distanceRangeLowInMillimeters: number;
      distanceRangeHighInMillimeters: number;
      componentTypeDescription?: string;
    } = {
      componentTypeName: name,
      distanceRangeLowInMillimeters: mindistance,
      distanceRangeHighInMillimeters: maxdistance,
    };
    if (description !== undefined) {
      body.componentTypeDescription = description;
    }
    return this.http.put<{ typeId: string }>(`${this.componentsUrl}${solutionSpace}/Type/${componentTypeId}`, body);
  }

  deleteComponentType(componentTypeId: string): Observable<Object> {
    return this.http.delete(`${this.componentsUrl}Type/${componentTypeId}`);
  }

  addComponentsToProject(projectId: string, componentIds: string[]): Observable<Object> {
    return this.http
      .post<{ projectId: string }>(`${this.componentsUrl}AddComponentsToProject`, {
        projectId,
        componentIds,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return throwError(() => 'ERROR.COMPONENT_PROJECT_CHANGE_400');
          }

          return throwError(() => error);
        })
      );
  }

  archiveComponent(id: string): Observable<HttpErrorResponse> {
    return this.http.post<HttpErrorResponse>(`${this.componentsUrl}${id}/archive`, {});
  }

  archiveComponents(
    componentIds: string[]
    // eslint-disable-next-line @typescript-eslint/ban-types
  ): Observable<Object> {
    return this.http.post(`${this.componentsUrl}ArchiveMultiple`, componentIds);
  }

  putPreferences(preferences: Preferences, componentId: string, solution: SolutionSpace): Observable<Object> {
    const payload: Preferences = {
      ...preferences,
    };
    // convert the string value to integer
    payload.shockWarningValue = +payload.shockWarningValue;
    payload.humidityWarningValue = +payload.humidityWarningValue;
    payload.shockMeasurementValue = +payload.shockMeasurementValue;
    return this.http.put<Preferences>(`${this.componentsUrl}${solution}/${componentId}/Preferences`, payload);
  }

  deletePreferences(componentId: string): Observable<Object> {
    return this.http.delete(`${this.componentsUrl}${componentId}/Preferences`);
  }

  getTypes(
    solutionSpace: SolutionSpace,
    pageRequest?: PageRequest<ComponentTypesSortOrder>,
    isPositioned?: boolean
  ): Observable<ComponentTypeList> {
    let params = new HttpParams();

    if (pageRequest?.pageNumber !== undefined) {
      params = params.set('pageNumber', pageRequest.pageNumber.toString());
    }
    if (pageRequest?.pageSize !== undefined) {
      params = params.set('pageSize', pageRequest.pageSize.toString());
    }
    if (pageRequest?.sortDirection !== undefined) {
      params = params.set('sortDirection', pageRequest.sortDirection.toString());
    }
    if (pageRequest?.sortOrder !== undefined) {
      params = params.set('sortOrder', pageRequest.sortOrder.toString());
    }
    if (isPositioned !== undefined) {
      params = params.set('isPositioned', isPositioned.toString());
    }
    return this.http.get<ComponentTypeList>(`${this.componentsUrl}${solutionSpace}/types?${params.toString()}`);
  }

  getLocations(filter: string): Observable<LocationInfo[]> {
    let params = new HttpParams();
    if (filter !== undefined) {
      params = params.set('nameContains', filter);
    }

    return this.http
      .get<CountryLocations>(`${this.apiUrl}Location?${params.toString()}`)
      .pipe(map((countryLocation) => this.flatMapToLocationInfo(countryLocation.countries)));
  }

  getComponents(
    solutionSpace: SolutionSpace,
    options: {
      states?: Array<ComponentState>;
      pageRequest?: PageRequest<ComponentsSortOrder>;
      likeSearch?: string;
      projectIds?: Array<string>;
      componentTypeIds?: Array<string>;
      isPositioned?: boolean;
      currentCycleAvailableDaysGTE?: number;
      currentCycleAvailableDaysLTE?: number;
      currentCycleInUseDaysGTE?: number;
      currentCycleInUseDaysLTE?: number;
      currentCycleInReturnDaysGTE?: number;
      currentCycleInReturnDaysLTE?: number;
      currentCycleInServiceDaysGTE?: number;
      currentCycleInServiceDaysLTE?: number;
      countryIds?: Array<string>;
      regionIds?: Array<string>;
      placeIds?: Array<string>;
      postcodeIds?: Array<string>;
      lastCommunicationTimeFrom?: string;
      lastCommunicationTimeTo?: string;
      // for just humidity devices
      latestExternalHumidityMeasurementValueGTE?: number;
      latestExternalHumidityMeasurementValueLTE?: number;
      latestExternalTemperatureMeasurementValueGTE?: number;
      latestExternalTemperatureMeasurementValueLTE?: number;
      minLat?: number;
      maxLat?: number;
      minLon?: number;
      maxLon?: number;
    }
  ): Observable<BackendComponentList> {
    let params = new HttpParams();
    if (options.likeSearch !== undefined) {
      params = params.set('likeSearch', options.likeSearch);
    }
    if (options.states !== undefined) {
      options.states.forEach((state) => {
        params = params.append('states', state);
      });
    }
    if (options.pageRequest?.sortDirection !== undefined) {
      params = params.set('sortDirection', options.pageRequest.sortDirection.toString());
    }
    if (options.pageRequest?.sortOrder !== undefined) {
      params = params.set('sortOrder', options.pageRequest.sortOrder.toString());
    }

    if (options.pageRequest?.pageNumber !== undefined) {
      params = params.set('pageNumber', options.pageRequest.pageNumber.toString());
    }
    if (options.pageRequest?.pageSize !== undefined) {
      params = params.set('pageSize', options.pageRequest.pageSize.toString());
    }
    if (options.projectIds !== undefined) {
      options.projectIds.forEach((id) => {
        params = params.append('projectIds', id);
      });
    }
    if (options.componentTypeIds !== undefined) {
      options.componentTypeIds.forEach((id) => {
        params = params.append('componentTypeIds', id);
      });
    }
    if (options.isPositioned !== undefined) {
      params = params.set('isPositioned', options.isPositioned.toString());
    }

    if (
      isDefined(options.minLat) &&
      isDefined(options.maxLat) &&
      isDefined(options.minLon) &&
      isDefined(options.maxLon)
    ) {
      params = params.set('minLat', options.minLat);
      params = params.set('maxLat', options.maxLat);
      params = params.set('minLon', options.minLon);
      params = params.set('maxLon', options.maxLon);
    }

    // for  Utilization devices
    if (solutionSpace === SolutionSpace.Utilization) {
      if (options.currentCycleAvailableDaysGTE !== undefined) {
        params = params.set('currentCycleAvailableDaysGTE', options.currentCycleAvailableDaysGTE.toString());
      }

      if (options.currentCycleAvailableDaysLTE !== undefined) {
        params = params.set('currentCycleAvailableDaysLTE', options.currentCycleAvailableDaysLTE.toString());
      }
      if (options.currentCycleInUseDaysGTE !== undefined) {
        params = params.set('currentCycleInUseDaysGTE', options.currentCycleInUseDaysGTE.toString());
      }
      if (options.currentCycleInUseDaysLTE !== undefined) {
        params = params.set('currentCycleInUseDaysLTE', options.currentCycleInUseDaysLTE.toString());
      }
      if (options.currentCycleInReturnDaysGTE !== undefined) {
        params = params.set('currentCycleInReturnDaysGTE', options.currentCycleInReturnDaysGTE.toString());
      }
      if (options.currentCycleInReturnDaysLTE !== undefined) {
        params = params.set('currentCycleInReturnDaysLTE', options.currentCycleInReturnDaysLTE.toString());
      }
      if (options.currentCycleInServiceDaysGTE !== undefined) {
        params = params.set('currentCycleInServiceDaysGTE', options.currentCycleInServiceDaysGTE.toString());
      }
      if (options.currentCycleInServiceDaysLTE !== undefined) {
        params = params.set('currentCycleInServiceDaysLTE', options.currentCycleInServiceDaysLTE.toString());
      }
    }
    if (solutionSpace === SolutionSpace.Humidity) {
      if (options.latestExternalHumidityMeasurementValueGTE !== undefined) {
        params = params.set(
          'latestExternalHumidityMeasurementValueGTE',
          options.latestExternalHumidityMeasurementValueGTE.toString()
        );
      }
      if (options.latestExternalHumidityMeasurementValueLTE !== undefined) {
        params = params.set(
          'latestExternalHumidityMeasurementValueLTE',
          options.latestExternalHumidityMeasurementValueLTE.toString()
        );
      }
      if (options.latestExternalTemperatureMeasurementValueGTE !== undefined) {
        params = params.set(
          'latestExternalTemperatureMeasurementValueGTE',
          options.latestExternalTemperatureMeasurementValueGTE.toString()
        );
      }
      if (options.latestExternalTemperatureMeasurementValueLTE !== undefined) {
        params = params.set(
          'latestExternalTemperatureMeasurementValueLTE',
          options.latestExternalTemperatureMeasurementValueLTE.toString()
        );
      }
    }

    if (options.countryIds !== undefined) {
      options.countryIds.forEach((state) => {
        params = params.append('countryIds', state);
      });
    }
    if (options.regionIds !== undefined) {
      options.regionIds.forEach((state) => {
        params = params.append('regionIds', state);
      });
    }
    if (options.placeIds !== undefined) {
      options.placeIds.forEach((state) => {
        params = params.append('placeIds', state);
      });
    }
    if (options.postcodeIds !== undefined) {
      options.postcodeIds.forEach((state) => {
        params = params.append('postcodeIds', state);
      });
    }
    if (options.lastCommunicationTimeFrom !== undefined) {
      params = params.set('lastCommunicationTimeFrom', options.lastCommunicationTimeFrom);
    }
    if (options.lastCommunicationTimeTo !== undefined) {
      params = params.set('lastCommunicationTimeTo', options.lastCommunicationTimeTo);
    }
    return this.http.get<BackendComponentList>(`${this.componentsUrl}${solutionSpace}?${params.toString()}`).pipe(
      map((list: BackendComponentList) => {
        return {
          items: list.items.map((c) => {
            return {
              id: c.id,
              name: c.name,
              groupName: c.groupName,
              groupId: c.groupId,
              projectName: c.projectName,
              projectId: c.projectId,
              projectDescription: c.projectDescription,
              isCurrentlyInProject: c.isCurrentlyInProject,
              componentTypeId: c.componentTypeId,
              componentTypeDescription: c.componentTypeDescription,
              componentTypeName: c.componentTypeName,
              deviceId: c.deviceId,
              deviceIdentifier: c.deviceIdentifier, // not provided by backend
              deviceType: c.deviceType, // not provided by backend
              position: c.position,
              archived: c.archived,
              componentHealth: c.componentHealth,
              state: c.state,
              cycles: c.cycles,
              currentCycleDaysAvailable: c.currentCycleDaysAvailable,
              currentCycleDaysInUse: c.currentCycleDaysInUse,
              currentCycleDaysInReturn: c.currentCycleDaysInReturn,
              currentCycleDaysInService: c.currentCycleDaysInService,
              lastKnownCountryId: c.lastKnownCountryId,
              lastKnownCountryName: c.lastKnownCountryName,
              lastKnownRegionId: c.lastKnownRegionId,
              lastKnownRegionName: c.lastKnownRegionName,
              lastKnownPlaceId: c.lastKnownPlaceId,
              lastKnownPlaceName: c.lastKnownPlaceName,
              lastKnownPostcodeId: c.lastKnownPostcodeId,
              lastKnownPostcodeName: c.lastKnownPostcodeName,
            };
          }),
          totalItemCount: list.totalItemCount,
          pageCount: list.pageCount,
          hasNextPage: list.hasNextPage,
          hasPreviousPage: list.hasPreviousPage,
        };
      })
    );
  }

  getUtilStatistics(componentId: string): Observable<HistoricalUtilStatistic> {
    return this.http.get<HistoricalUtilStatistic>(`${this.componentsUrl}${componentId}/statistics`);
  }

  receivedInService(componentId: string) {
    return this.http.post(`${this.componentsUrl}${componentId}/ReceiveInService`, {});
  }

  ReleaseFromService(componentId: string) {
    return this.http.post(`${this.componentsUrl}${componentId}/ReleaseFromService`, {});
  }

  getMeasurementsData(
    componentId: string,
    measurements: MeasurementsType[],
    dateTimeLimitFrom?: Date,
    dateTimeLimitTo?: Date
  ): Observable<Measurement[]> {
    // refactor MetaData for the right return value
    let params = new HttpParams();
    if (dateTimeLimitFrom !== undefined) {
      params = params.set('dateTimeLimitFrom', dateTimeLimitFrom.toISOString());
    }
    if (dateTimeLimitTo !== undefined) {
      params = params.set('dateTimeLimitTo', dateTimeLimitTo.toISOString());
    }
    measurements.forEach((measurement) => {
      params = params.append('measurementTypes', measurement.toString());
    });
    return this.http.get<Measurement[]>(`${this.componentsUrl}${componentId}/Measurements?${params.toString()}`);
  }

  private flatMapToLocationInfo(countries: Country[]): LocationInfo[] {
    const flattenLocations = countries.flatMap((country) => {
      const mappedCountries = this.mapToLocationInfo(country.id, country.name, LocationType.COUNTRY);
      const flattenRegionLoc = country.regions.flatMap((region) => {
        const mappedRegion = this.mapToLocationInfo(region.id, region.name, LocationType.REGION);
        const flattenPlaceLoc = region.places.flatMap((place) => {
          const mappedPlaces = this.mapToLocationInfo(place.id, place.name, LocationType.PLACE);
          const flattenPostcodeLoc = place.postcodes.flatMap((postcode) => {
            return this.mapToLocationInfo(postcode.id, postcode.name, LocationType.POSTCODE);
          });

          return [mappedPlaces, ...flattenPostcodeLoc];
        });

        return [mappedRegion, ...flattenPlaceLoc];
      });

      return [mappedCountries, ...flattenRegionLoc];
    });
    return flattenLocations;
  }

  private mapToLocationInfo(id: string, name: string, type: LocationType): LocationInfo {
    return {
      id: id,
      name: name,
      type: type,
    };
  }
}
