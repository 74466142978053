export interface PageRequest<TSortOrder> {
  pageNumber?: number;
  pageSize?: number;
  sortOrder?: TSortOrder;
  sortDirection?: SortDirection;
}

export enum IncidentsSortOrder {
  CREATED,
  MEASUREMENT_TIME,
}

export enum SortDirection {
  ASC,
  DESC,
}
