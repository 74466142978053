import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Device } from 'src/app/models/device';
import { NumericUtilOrHumid } from 'src/app/models/solution';
import { environment } from 'src/environments/environment';
import { SortDirection } from './components.service';
import { UserService } from './user.service';
export interface PageableDevices {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: Device[];
  pageCount: number;
  totalItemCount: number;
}
export enum DevicesSortOrder {
  IDENTIFIER,
  LAST_COMMUNICATED,
  FIRMWARE,
}
@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  constructor(private http: HttpClient, private userService: UserService) {}

  private get devicesUrl(): string {
    return environment.api + `/api/${this.userService.getOrganisationId()}/devices/`;
  }

  getAllDevices(
    sortOrder: DevicesSortOrder,
    sortDirection: SortDirection,
    groupIds: Array<string>,
    solutionSpace?: NumericUtilOrHumid,
    isAttachedToComponent?: boolean,
    search?: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PageableDevices> {
    let params = new HttpParams();
    if (solutionSpace !== undefined) {
      params = params.set('solutionSpace', solutionSpace.toString());
    }
    if (search !== undefined) {
      params = params.set('search', search);
    }
    params = params.set('sortOrder', sortOrder.toString());
    params = params.set('sortDirection', sortDirection.toString());
    groupIds.forEach((id) => {
      params = params.append('groupIds', id);
    });
    if (isAttachedToComponent !== undefined) {
      params = params.set('isAttachedToComponent', isAttachedToComponent.toString());
    }
    if (search !== undefined) {
      params = params.set('search', search);
    }
    if (pageNumber !== undefined) {
      params = params.set('pageNumber', pageNumber.toString());
    }
    if (pageSize !== undefined) {
      params = params.set('pageSize', pageSize.toString());
    }
    return this.http.get<PageableDevices>(`${this.devicesUrl}devices?${params.toString()}`);
  }
}
