import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { Subscription } from 'rxjs';
import {
  BackendComponentActivity,
  BackendComponentActivityDescription,
} from 'src/app/models/backend/backend-component-activity';
import { ComponentsService } from 'src/app/services/api/components.service';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { ActivityType } from 'src/app/shared/recent-history/recent-history.component';
import { formatDate } from 'src/app/shared/utils';

export interface Journey {
  type: ActivityType;
  startDate: Date;
  endDate: Date;
  showTooltip: boolean;
}
interface Cycle {
  startDate: string;
  endDate: string;
  journey: Array<Journey>;
  isCompleted: boolean;
  totalCycleDays: number;
}
@Component({
  selector: 'app-zx-component-life-cycle',
  templateUrl: './zx-component-life-cycle.component.html',
  styleUrls: ['./zx-component-life-cycle.component.scss'],
})
export class ZxComponentLifeCycleComponent implements OnInit {
  private paramSubscription: Subscription;
  componentId = '';
  activities: { timestamp: Date; activity: ActivityType }[] = [];
  cycles: Array<Cycle> = [];
  activityType = ActivityType;
  isLoaded = false;

  constructor(
    private componentsService: ComponentsService,
    private route: ActivatedRoute,
    private responseHandler: ResponseHandlerService
  ) {}
  getActivityType() {
    return ActivityType;
  }
  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe((el) => {
      this.componentId = el.id as string;
      this.fetchActivities();
    });
  }

  fetchActivities() {
    const limits = 100;
    const descriptions: Array<BackendComponentActivityDescription> = [
      BackendComponentActivityDescription.Created_By_Username,
      BackendComponentActivityDescription.Unloaded,
      BackendComponentActivityDescription.Loaded,
      BackendComponentActivityDescription.Received_In_Service,
      BackendComponentActivityDescription.Released_From_Service, // cycle ends here
    ];
    this.componentsService.getComponentActivity(this.componentId, limits, descriptions).subscribe(
      (activities) => {
        this.activities = activities
          .map((a) => plainToClass(BackendComponentActivity, a))
          .map((a) => ({
            timestamp: a.occurredAt,
            activity: a.getActivityType(),
          }))
          .reverse();
        this.prepareCycles(this.activities);
        this.isLoaded = true;
      },
      (error: HttpErrorResponse) => this.responseHandler.error(error)
    );
  }

  prepareCycles(activities: Array<{ timestamp: Date; activity: ActivityType }>): void {
    const indexsOfCycle: Array<number> = [];
    for (let i = 0; i < activities.length; i++) {
      const act = activities[i];
      if (act.activity === ActivityType.Created || act.activity === ActivityType.inService) {
        indexsOfCycle.push(i);
      }
    }
    if (indexsOfCycle.length === 1) {
      // created advent
      const indexCycle = indexsOfCycle[0];
      const activityLength = this.activities.length;
      const journey: Array<Journey> = this.activities.map((act, index) => {
        return {
          type: act.activity,
          startDate: act.timestamp,
          endDate: this.activities[index + 1] ? this.activities[index + 1].timestamp : act.timestamp,
          showTooltip: true,
        };
      });
      const isCompleted = this.activities[activityLength - 1].activity === ActivityType.inService ? true : false;
      const cycle = this.prepareCycle(
        journey,
        this.activities[indexCycle].timestamp,
        this.activities[activityLength - 1].timestamp,
        isCompleted
      );
      this.cycles.push(cycle);
    } else {
      // more than one cycle is detected !!

      for (let j = 0; j < indexsOfCycle.length; j++) {
        if (j + 1 < indexsOfCycle.length) {
          const act = this.activities.slice(indexsOfCycle[j], indexsOfCycle[j + 1] + 1);
          const journey: Array<Journey> = act.map((a, index) => {
            return {
              type: a.activity,
              startDate: a.timestamp,
              endDate: act[index + 1] ? act[index + 1].timestamp : a.timestamp,
              showTooltip: true,
            };
          });
          const isCompleted = this.activities[indexsOfCycle[j + 1]].activity === ActivityType.inService ? true : false;
          const cycle = this.prepareCycle(
            journey,
            this.activities[indexsOfCycle[j]].timestamp,
            this.activities[indexsOfCycle[j + 1]].timestamp,
            isCompleted
          );
          this.cycles.push(cycle);
        }
      }
      const cyclesLength = indexsOfCycle.length - 1;
      // the rest of the cycle is considered new cycle ecven if it's not finished, it should be added !!
      if (indexsOfCycle[cyclesLength] < activities.length - 1) {
        const act = this.activities.slice(indexsOfCycle[cyclesLength], this.activities.length);

        const journey: Array<Journey> = act.map((a, index) => {
          return {
            type: a.activity,
            startDate: a.timestamp,
            endDate: act[index + 1] ? act[index + 1].timestamp : a.timestamp,
            showTooltip: true,
          };
        });
        const isCompleted =
          this.activities[this.activities.length - 1].activity === ActivityType.inService ? true : false;
        const cycle = this.prepareCycle(
          journey,
          this.activities[indexsOfCycle[cyclesLength]].timestamp,
          this.activities[this.activities.length - 1].timestamp,
          isCompleted
        );
        this.cycles.push(cycle);
      } else if (activities[indexsOfCycle[cyclesLength]].activity === ActivityType.inService) {
        // createe another cycle with just inservice journey to allow releasing it in the next cycle

        const journey = [
          {
            type: activities[indexsOfCycle[cyclesLength]].activity,
            startDate: activities[indexsOfCycle[cyclesLength]].timestamp,
            endDate: activities[indexsOfCycle[cyclesLength]].timestamp,
            showTooltip: false,
          },
        ];
        const isCompleted =
          this.activities[this.activities.length - 1].activity === ActivityType.inService ? true : false;
        const cycle = this.prepareCycle(
          journey,
          this.activities[indexsOfCycle[cyclesLength]].timestamp,
          this.activities[this.activities.length - 1].timestamp,
          isCompleted
        );

        this.cycles.push(cycle);
      }
    }
  }
  // repeater to make it available in the template
  formatDateForTooltip(date: Date): string {
    return date.toLocaleDateString();
  }

  displayTooltipText(journey: Journey): string {
    const text = `${this.formatDateForTooltip(journey.startDate)} - ${this.formatDateForTooltip(journey.endDate)}
    ${this.getDaysDifferencebetweenTwoDays(journey.endDate, journey.startDate)}  Days ${this.getJourneyDesc(
      journey.type
    )}`;
    return text;
  }

  calculateJourneyWidth(endDate: Date, startDate: Date, totalCycleDuration: number): number | string {
    const journeyDuration = this.getDaysDifferencebetweenTwoDays(endDate, startDate);
    return journeyDuration / totalCycleDuration !== 0 ? (journeyDuration / totalCycleDuration) * 100 : 'unset';
  }

  prepareCycle(journeys: Array<Journey>, startDate: Date, endDate: Date, isCompleted: boolean): Cycle {
    const cycle = {
      journey: journeys,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      isCompleted: isCompleted,
      totalCycleDays: this.getDaysDifferencebetweenTwoDays(endDate, startDate),
    };

    // disable tooltip for inservice last journey
    const lastElementInJourney = cycle.journey.length - 1;
    if (cycle.isCompleted) {
      if (cycle.journey[lastElementInJourney].type === ActivityType.inService) {
        cycle.journey[lastElementInJourney].showTooltip = false;
      }
    } else {
      // cycle is not completed , the last journey end date should be today'S date
      if (cycle.journey[lastElementInJourney].type !== ActivityType.inService) {
        cycle.journey[lastElementInJourney].endDate = new Date();
      }
    }
    cycle.totalCycleDays = cycle.journey.reduce(
      (sum, journey) => sum + this.getDaysDifferencebetweenTwoDays(journey.endDate, journey.startDate),
      0
    );
    return cycle;
  }

  getJourneyIcon(activity: ActivityType): string {
    switch (activity) {
      case ActivityType.Created:
        return '/assets/icons/journey/active.svg';
      case ActivityType.Available:
        return '/assets/icons/journey/active.svg';
      case ActivityType.InUse:
        return '/assets/icons/journey/inUse.svg';
      case ActivityType.inReturn:
        return '/assets/icons/journey/inReturn.svg';
      case ActivityType.inService:
        return '/assets/icons/journey/service.svg';
      default:
        return '<error>';
    }
  }

  getJourneyDesc(activity: ActivityType): string {
    switch (activity) {
      case ActivityType.Created:
        return 'Available';
      case ActivityType.Available:
        return 'Available';
      case ActivityType.InUse:
        return 'In Use';
      case ActivityType.inReturn:
        return 'In Return';
      case ActivityType.inService:
        return 'In Service';
      default:
        return '<error>';
    }
  }

  setStateToReceived() {
    this.componentsService.receivedInService(this.componentId).subscribe(
      () => {
        this.cycles = [];
        this.fetchActivities();
      },
      (error: HttpErrorResponse) => this.responseHandler.error(error)
    );
  }
  getDaysDifferencebetweenTwoDays(endDate: Date, startDate: Date): number {
    const difference = endDate.getTime() - startDate.getTime();
    const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return totalDays;
  }
  setStateToReleasedFromService() {
    this.componentsService.ReleaseFromService(this.componentId).subscribe(
      () => {
        this.cycles = [];
        this.fetchActivities();
      },
      (error: HttpErrorResponse) => this.responseHandler.error(error)
    );
  }
}
