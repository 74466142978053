/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/unbound-method */
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { zxProjectModel } from 'src/app/models/zxProject.model';
import { SolutionSpace } from '../../models/solution';
import { catchError, map } from 'rxjs/operators';
import { Preferences } from 'src/app/models/preferences.model';
import { environment } from 'src/environments/environment';
import { ProjectList } from 'src/app/models/project-list';
import { BackendComponentList, SortDirection, sortOrder } from './components.service';
import { UserService } from './user.service';
import { stripUndefined } from 'src/app/shared/utils';
import { ComponentState, ZXComponent } from 'src/app/models/component';
import { IncidentsPageable } from 'src/app/models/incident';
import { Datalog } from 'src/app/models/datalog';
import {
  BackendComponentActivity,
  BackendComponentActivityDescription,
} from 'src/app/models/backend/backend-component-activity';
import { Measurement, MeasurementsType } from 'src/app/models/measurement';
import { datalogMapper } from 'src/app/models/transforms';
import { HistoricalUtilStatistic } from 'src/app/models/historicalUtilStatistic';
import { IncidentsSortOrder, PageRequest } from 'src/app/models/page-request';
import { IncidentFilter } from 'src/app/models/incident-filter';

export class BackendCreateProject {
  solution?: string;
  groupId?: string;
  name?: string;
  description?: string;
}

export class BackendUpdateProject {
  name?: string;
  description?: string;
}

export type ProjectComponentFilter = {
  isPositioned?: boolean;
  likeSearch?: string;
  componentTypeIds?: string[];
  deviceIdentifiers?: string[];
  states?: ComponentState[];
  countryIds?: string[];
  regionIds?: string[];
  placeIds?: string[];
  postcodeIds?: string[];
  latestExternalHumidityMeasurementValueGTE?: number;
  latestExternalHumidityMeasurementValueLTE?: number;
  latestExternalTemperatureMeasurementValueGTE?: number;
  latestExternalTemperatureMeasurementValueLTE?: number;
  cyclesGTE?: number;
  cyclesLTE?: number;
  currentCycleAvailableDaysGTE?: number;
  currentCycleAvailableDaysLTE?: number;
  currentCycleInUseDaysGTE?: number;
  currentCycleInUseDaysLTE?: number;
  currentCycleInReturnDaysGTE?: number;
  currentCycleInReturnDaysLTE?: number;
  currentCycleInServiceDaysGTE?: number;
  currentCycleInServiceDaysLTE?: number;
  lastCommunicationTimeFrom?: string;
  lastCommunicationTimeTo?: string;
  minLat?: number;
  maxLat?: number;
  minLon?: number;
  maxLon?: number;
};

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  projectsUrl: string;

  constructor(private http: HttpClient, userService: UserService) {
    this.projectsUrl = `${environment.api}/api/${userService.getOrganisationId()}/project/`;
  }

  createProject(projectCreate: BackendCreateProject, solution: SolutionSpace): Observable<string> {
    return this.http.post<string>(`${this.projectsUrl}${solution}`, projectCreate).pipe(catchError(this.handleError));
  }

  editProject(projectUpdate: BackendUpdateProject, id: string) {
    return this.http.put<BackendUpdateProject>(`${this.projectsUrl}${id}`, projectUpdate);
  }

  archiveProject(projectId: string): Observable<string> {
    return this.http.put<string>(`${this.projectsUrl}${projectId}/Archive`, {}).pipe(catchError(this.handleError));
  }

  addComponent(componentId: string, projectId: string) {
    return this.http
      .post<{ componentId: string }>(`${this.projectsUrl}${projectId}/AddComponent`, { componentId })
      .pipe(catchError(this.handleError));
  }

  removeComponent(componentId: string, projectId: string) {
    return this.http
      .put<{ componentId: string }>(`${this.projectsUrl}${projectId}/RemoveComponent`, { componentId })
      .pipe(catchError(this.handleError));
  }

  updateProjectPreferences(projectId: string, preferences: Preferences) {
    return this.http
      .put<Preferences>(`${this.projectsUrl}${projectId}/Preferences`, preferences)
      .pipe(catchError(this.handleError));
  }

  getProjects(
    solutionSpace: SolutionSpace | string,
    pageNumber?: number,
    pageSize?: number,
    sortDirection?: SortDirection,
    sortOrder?: sortOrder,
    likeSearch?: string,
    archived?: boolean,
    isPositioned?: boolean,
    groupIds?: string[]
  ): Observable<ProjectList> {
    let params = new HttpParams();
    if (likeSearch !== undefined) {
      params = params.set('likeSearch', likeSearch);
    }
    if (archived !== undefined) {
      params = params.set('isArchived', archived.toString());
    }
    if (sortDirection !== undefined) {
      params = params.set('sortDirection', sortDirection.toString());
    }
    if (sortOrder !== undefined) {
      params = params.set('sortOrder', sortOrder.toString());
    }
    if (pageNumber !== undefined) {
      params = params.set('pageNumber', pageNumber.toString());
    }
    if (pageSize !== undefined) {
      params = params.set('pageSize', pageSize.toString());
    }
    if (isPositioned !== undefined) {
      params = params.set('isPositioned', isPositioned.toString());
    }
    if (groupIds !== undefined) {
      groupIds.forEach((id) => {
        params = params.append('groupIds', id);
      });
    }

    return this.http.get<ProjectList>(`${this.projectsUrl}${solutionSpace}?${params.toString()}`);
  }

  getProject(solutionSpace: SolutionSpace, projectId: string): Observable<zxProjectModel> {
    return this.http.get<zxProjectModel>(`${this.projectsUrl}${solutionSpace}/${projectId}`);
  }

  patchPreference(
    updateObject:
      | { humidityWarning: boolean; humidityWarningValue: number }
      | { shockWarning: boolean; shockWarningValue: number }
      | { batteryAlert: boolean; batteryAlertValue: number }
      | { noCommAlert: boolean },
    solution: SolutionSpace
  ) {
    return this.http.patch<unknown>(`${this.projectsUrl}${solution}`, updateObject);
  }

  getComponents(
    solutionSpace: SolutionSpace,
    projectId: string,
    filter: ProjectComponentFilter,
    pagination: {
      pageNumber?: number;
      pageSize?: number;
      sortDirection?: SortDirection;
      sortOrder?: sortOrder;
    } = { pageNumber: 1, pageSize: 5000000 }
  ): Observable<BackendComponentList> {
    return this.http.get<BackendComponentList>(`${this.projectsUrl}${solutionSpace}/${projectId}/component/`, {
      params: { ...pagination, ...stripUndefined(filter) },
    });
  }

  getComponent(solutionSpace: SolutionSpace, projectId: string, componentId: string): Observable<ZXComponent> {
    return this.http.get<ZXComponent>(`${this.projectsUrl}${solutionSpace}/${projectId}/component/${componentId}`);
  }

  getComponentDatalog(projectId: string, componentId: string, startDate: Date, endDate: Date): Observable<Datalog[]> {
    let params = new HttpParams();
    if (startDate !== undefined) {
      params = params.set('StartTimeUtc', startDate.toISOString());
    }
    if (endDate !== undefined) {
      params = params.set('StopTimeUtc', endDate.toISOString());
    }
    return this.http
      .get<Datalog[]>(`${this.projectsUrl}${projectId}/Component/${componentId}/datalog?${params.toString()}`)
      .pipe(
        map((datalogs) => {
          datalogs.map((datalog) => datalogMapper(datalog));

          return datalogs;
        })
      );
  }

  getComponentActivity(
    projectId: string,
    componentId: string,
    limit?: number,
    descriptions?: Array<BackendComponentActivityDescription>
  ): Observable<BackendComponentActivity[]> {
    let params = new HttpParams();
    if (limit !== undefined) {
      params = params.set('limit', limit.toString());
    }
    if (descriptions !== undefined) {
      descriptions.forEach((desc) => {
        params = params.append('descriptions', desc);
      });
    }
    return this.http.get<BackendComponentActivity[]>(
      `${this.projectsUrl}${projectId}/Component/${componentId}/activity?${params.toString()}`
    );
  }

  getComponentMeasurementsData(
    projectId: string,
    componentId: string,
    measurements: MeasurementsType[],
    dateTimeLimitFrom?: Date,
    dateTimeLimitTo?: Date
  ): Observable<Measurement[]> {
    let params = new HttpParams();
    if (dateTimeLimitFrom !== undefined) {
      params = params.set('dateTimeLimitFrom', dateTimeLimitFrom.toISOString());
    }
    if (dateTimeLimitTo !== undefined) {
      params = params.set('dateTimeLimitTo', dateTimeLimitTo.toISOString());
    }
    measurements.forEach((measurement) => {
      params = params.append('measurementTypes', measurement.toString());
    });
    return this.http.get<Measurement[]>(
      `${this.projectsUrl}${projectId}/Component/${componentId}/measurements?${params.toString()}`
    );
  }

  getIncidents(
    projectId: string,
    filter: IncidentFilter,
    pagination: PageRequest<IncidentsSortOrder>
  ): Observable<IncidentsPageable> {
    return this.http.get<IncidentsPageable>(`${this.projectsUrl}${projectId}/incidents`, {
      params: { ...pagination, ...filter },
    });
  }

  getComponentIncidents(
    projectId: string,
    componentId: string,
    filter: IncidentFilter,
    pagination: PageRequest<IncidentsSortOrder>
  ): Observable<IncidentsPageable> {
    return this.http.get<IncidentsPageable>(`${this.projectsUrl}${projectId}/Component/${componentId}/incidents`, {
      params: { ...filter, ...pagination },
    });
  }

  getComponentStatistics(projectId: string, componentId: string): Observable<HistoricalUtilStatistic> {
    return this.http.get<HistoricalUtilStatistic>(
      `${this.projectsUrl}${projectId}/Component/${componentId}/statistics`
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${error.error.message}`;
    } else if (error.error === null) {
      errorMessage = error.message;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const bodyMessage: string =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        error.error.ErrorMessage || error.error.toString();
      errorMessage = `Backend returned code ${error.status}, body was: ${bodyMessage}`;
    }
    return throwError(errorMessage);
  }
}
