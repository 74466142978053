import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IncidentsPageable, IncidentUpdateState } from 'src/app/models/incident';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { IncidentFilter } from 'src/app/models/incident-filter';
import { IncidentsSortOrder, PageRequest } from 'src/app/models/page-request';

export enum IncidentType {
  NONE = 98,
  BATTERY_LEVEL = 0,
  SHOCK = 1,
  TEMPERATURE = 2,
  HUMIDITY = 3,
  NormalizedShockEnergy = 4,
}

@Injectable({
  providedIn: 'root',
})
export class IncidentsService {
  constructor(private http: HttpClient, private userService: UserService) {}

  getIncidentsUrl(): string {
    return `${environment.api}/api/${this.userService.getOrganisationId()}/Incident`;
  }

  getIncidents(
    componentId: string,
    filter: IncidentFilter,
    pagination: PageRequest<IncidentsSortOrder>
  ): Observable<IncidentsPageable> {
    return this.http.get<IncidentsPageable>(this.getIncidentsUrl(), {
      params: { ...filter, ...pagination, ...{ componentIds: componentId } },
    });
  }

  updateIncident(id: string, state: IncidentUpdateState, comment: string): Observable<unknown> {
    return this.http.put(`${this.getIncidentsUrl()}/${id}`, {
      state,
      comment,
    });
  }
}
