import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject, combineLatest, map, switchMap } from 'rxjs';
import { IncidentFilter } from 'src/app/models/incident-filter';
import { IncidentsSortOrder, PageRequest } from 'src/app/models/page-request';
import { SolutionSpace } from 'src/app/models/solution';
import { SortDirection } from 'src/app/services/api/components.service';
import { IncidentsService, IncidentType } from 'src/app/services/api/incidents.service';

@Component({
  selector: 'app-zx-component-incidents',
  templateUrl: './zx-component-incidents.component.html',
  styleUrls: ['./zx-component-incidents.component.scss'],
})
export class ZxComponentIncidentsComponent {
  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );

  componentId$ = this.route.params.pipe(map((el) => el.id as string));

  params: PageRequest<IncidentsSortOrder> & IncidentFilter = {
    pageNumber: 1,
    pageSize: 50,
    sortOrder: IncidentsSortOrder.MEASUREMENT_TIME,
    sortDirection: SortDirection.ASC,
    incidentTypes: [IncidentType.HUMIDITY, IncidentType.NormalizedShockEnergy],
    isAcknowledged: false,
  };

  filter$ = new ReplaySubject<IncidentFilter>(1);
  pagination$ = new ReplaySubject<PageRequest<IncidentsSortOrder>>(1);

  incidents$ = combineLatest([this.componentId$, this.filter$, this.pagination$]).pipe(
    switchMap(([componentId, filter, pagination]) => this.incidentSerivce.getIncidents(componentId, filter, pagination))
  );

  constructor(private route: ActivatedRoute, private incidentSerivce: IncidentsService) {}

  onChangeIncidentsFilter(newFilter: IncidentFilter) {
    this.filter$.next(newFilter);
  }

  onChangePagination(newPagination: PageRequest<IncidentsSortOrder>) {
    this.pagination$.next(newPagination);
  }
}
