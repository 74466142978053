import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZxComponentsComponent } from './zx-components-list/zx-components.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZxComponentsOverviewComponent } from './zx-components-overview/zx-components-overview.component';
import { ZxComponentLifeCycleComponent } from './zx-component-life-cycle/zx-component-life-cycle.component';
import { ZxComponentInsightsComponent } from './zx-component-insights/zx-component-insights';

import { ZxComponentDetailComponent } from './zx-component-detail/zx-component-detail.component';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { ZxComponentHighlightCardComponent } from './zx-component-highlight-card/zx-component-highlight-card.component';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { ZxComponentDatalogComponent } from './zx-component-datalog/zx-component-datalog.component';
import { ZxComponentIncidentsComponent } from './zx-component-incidents/zx-component-incidents.component';

import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { ZxComponentCreateComponent } from './zx-component-create/zx-component-create.component';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatTooltipModule } from '@angular/material/tooltip';
import { ZxComponentDetailPreferencesComponent } from './zx-component-detail-preferences/zx-component-detail-preferences.component';
import { ZxComponentFormComponent } from './zx-component-form/zx-component-form.component';
import { ZxComponentEditComponent } from './zx-component-edit/zx-component-edit.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { CreateDialogComponent } from './zx-component-form/create-dialog/create-dialog.component';
import { SaveDialogComponent } from './zx-component-form/save-dialog/save-dialog.component';
import { ArchiveDialogComponent } from './zx-component-form/archive-dialog/archive-dialog.component';
import { LeaveDialogComponent } from './zx-component-form/leave-dialog/leave-dialog.component';
import { ArchiveComponentsComponent } from './zx-components-list/archive-components/archive-components.component';
import { SetProjectDialogComponent } from './zx-components-list/set-project-dialog/set-project-dialog.component';
import { TypeDialogComponent } from './zx-component-form/type-dialog/type-dialog.component';

import { PolytechUiModule } from '../shared/polytech-ui/polytech-ui.module';
import { ZxProjectComponentDetailComponent } from './zx-project-component-detail/zx-project-component-detail.component';
import { ZxProjectComponentDatalogComponent } from './zx-project-component-datalog /zx-project-component-datalog.component';
import { ZxProjectComponentIncidentsComponent } from './zx-project-component-incidents /zx-project-component-incidents.component';
import { ZxProjectComponentInsightsComponent } from './zx-project-component-insights/zx-project-component-insights';
import { ZxProjectComponentsOverviewComponent } from './zx-project-components-overview /zx-project-components-overview.component';

@NgModule({
  declarations: [
    ZxComponentsComponent,
    ZxComponentsOverviewComponent,
    ZxComponentLifeCycleComponent,
    ZxComponentInsightsComponent,
    ZxComponentDetailComponent,
    ZxComponentHighlightCardComponent,
    ZxComponentDatalogComponent,
    ZxComponentIncidentsComponent,
    ZxComponentCreateComponent,
    ZxComponentDetailPreferencesComponent,
    ZxComponentFormComponent,
    ZxComponentEditComponent,
    CreateDialogComponent,
    SaveDialogComponent,
    ArchiveDialogComponent,
    LeaveDialogComponent,
    ArchiveComponentsComponent,
    SetProjectDialogComponent,
    TypeDialogComponent,
    ZxProjectComponentDetailComponent,
    ZxProjectComponentDatalogComponent,
    ZxProjectComponentIncidentsComponent,
    ZxProjectComponentInsightsComponent,
    ZxProjectComponentsOverviewComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    FormsModule,
    RouterModule,
    MatTabsModule,
    MatCardModule,
    MatStepperModule,
    FlexModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTooltipModule,
    AngularSvgIconModule.forRoot(),
    MatProgressSpinnerModule,
    MatDialogModule,
    PolytechUiModule,
  ],
  exports: [ZxComponentsComponent],
})
export class ZxComponentsModule {}
